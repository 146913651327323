export const __t: (string: string) => string = (string: string) => {
    let lang = process.env.GATSBY_LANG;

    if (!['en-GB'].includes(lang) && lang?.includes('-')) {
        lang = lang.split('-')[0];
    }

    if (lang && lang !== 'en' && translates[lang] && translates[lang][string]) {
        return translates[lang][string];
    }

    return string;
}

const translates = {
    'en-GB': {
        "cart": "basket",
        "View cart": "View basket",
        "Your cart is currently empty!": "Your basket is currently empty!",
        "Looks like you have no items in your shopping cart.": "Looks like you have no items in your shopping basket.",
        "Return to cart": "Return to basket",
        "Add to cart": "Add to basket",
        "Your cart": "Your basket",
        "Apartment, suite, etc. (optional)": "Flat, suite, etc. (optional)",
    },
    'de': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Orthopädische Behandlung von Kopf bis Fuß",

        // other countries footer
        "United States": "Vereinigte Staaten",
        "Canada": "Kanada",
        "Ireland": "Irland",
        "New Zealand": "Neuseeland",
        "Germany": "Deutschland",
        "Spain": "Spanien",
        "Switzerland": "Schweiz",
        "France": "Frankreich",
        "United Kingdom": "Vereinigtes Königreich",
        "Australia": "Australien",
        "Austria": "Österreich",
        "Portugal": "Portugal",
        "Sweden": "Schweden",
        "Norway": "Norwegen",
        "Finland": "Finnland",
        "Denmark": "Dänemark",
        "Italy": "Italien",
        "Netherlands": "Niederlande",
        "Belgium": "Belgien",
        "Czech Republic": "Tschechische Republik",
        "Hungary": "Ungarn",
        "Romania": "Rumänien",
        "Greece": "Griechenland",

        //header offer
        "Sale Ends": "Der Verkauf endet am",
        "20-50% OFF+Free Shipping": "20-50% Rabatt + kostenloser Versand",

        //success page
        "Thank you!": "Wir danken Ihnen!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Ihre Bestellung ist bei uns eingegangen. Wir haben Ihnen soeben eine Bestätigungs-E-Mail geschickt. Öffnen Sie einfach Ihren E-Mail-Posteingang und suchen Sie nach der E-Mail. Halten Sie die Augen offen, denn die Bestätigungsmail könnte in Ihrem Spam-Ordner landen.",
        "Order details": "Details zur Bestellung",
        "Billing address": "Rechnungsadresse",
        "Shipping address": "Versandadresse",
        "Order Number": "Bestellnummer",
        "Date": "Datum",
        "Email": "E-Mail",
        "Total": "Gesamtbetrag",
        "Payment method": "Zahlungsmethode",
        "Subtotal": "Zwischensumme",
        "Shipping": "Versand",
        "Product": "Produkt",

        //applyCoupon
        "Coupon ": "Der Coupon ",
        " is applied successfully.": " wird erfolgreich angewendet.",
        "Coupon code": "Gutschein-Code",
        "Apply code": "Code anwenden",
        "Remove": "Entfernen",

        //cartSidebar
        "cart": "Warenkorb",
        "Shop now": "Jetzt einkaufen",
        "View cart": "Warenkorb ansehen",
        "Includes": "Enthält",

        //checkout options
        "Checkout": "Kasse",
        "Or": "Oder",
        "Express checkout": "Express-Kasse",
        "Have a promo code? Click here.": "Haben Sie einen Promo-Code? Klicken Sie hier.",
        "Coupon": "Coupon",
        "applied!": "angewendet!",
        "Payment": "Bezahlung",
        "All transaction are secure and encrypted.": "Alle Transaktionen sind sicher und verschlüsselt.",
        "Select the address that matches your card or payment method.": "Wählen Sie die Adresse, die mit Ihrer Karte oder Zahlungsmethode übereinstimmt.",
        "Same as shipping address": "Gleiche Adresse wie Lieferadresse",
        "Use a different billing address": "Verwenden Sie eine andere Rechnungsadresse",
        "Show order summary": "Bestellübersicht anzeigen",
        "Hide order summary": "Bestellübersicht ausblenden",

        //checkout errors
        "This value is required.": "Dieser Wert ist erforderlich.",
        "Must only contain digits 0 through 9.": "Darf nur die Ziffern 0 bis 9 enthalten.",
        "Invalid ZIP code.": "Ungültige Postleitzahl.",
        "Billing address is not filled in": "Rechnungsadresse ist nicht ausgefüllt",
        "Invalid email.": "Ungültige E-Mail.",

        //empty cart
        "Your cart is currently empty!": "Ihr Warenkorb ist derzeit leer!",
        "Looks like you have no items in your shopping cart.": "Es sieht so aus, als ob Sie keine Artikel in Ihrem Warenkorb hätten.",
        "Return to home page": "Zurück zur Startseite",

        //checkout bottom
        "Satisfaction": "Zufriedenheit",
        "Guaranteed": "Garantiert",
        "Free": "Kostenlos",
        "Exchange": "Umtausch",
        "Customer": "Kunden",
        "Service": "Dienst",

        //addressInputs
        "First name": "Vorname",
        "Last name": "Nachname",
        "Street address": "Straße",
        "Apartment, suite, etc. (optional)": "Wohnung, Appartement usw. (wahlweise)",
        "City": "Stadt",
        "Country / Region": "Land / Region",
        "State": "Bundesland",
        "Post Code / ZIP": "Postleitzahl / ZIP",
        "Phone": "Telefon",

        //checkout buttons 
        "Continue to shipping": "Weiter zum Versand",
        "Return to cart": "Zurück zum Warenkorb",
        "Continue to payment": "Weiter zur Bezahlung",
        "Return to information": "Zurück zu den Informationen",
        "Complete order": "Bestellung abschließen",
        "Return to shipping": "Zurück zum Versand",

        //information form
        "Email me with news and offers": "E-Mail mit Neuigkeiten und Angeboten",

        //payment form
        "Billing Information": "Informationen zur Rechnungsstellung",
        "Use a different billing address?": "Verwenden Sie eine andere Rechnungsadresse?",

        //payment options
        "Credit Card (Stripe)": "Kreditkarte (Stripe)",
        "Pay with your credit card via Stripe.": "Bezahlen Sie mit Ihrer Kreditkarte über Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Bezahlen Sie über PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Ihre persönlichen Daten werden verwendet, um Ihre Bestellung zu bearbeiten, Ihre Erfahrung auf dieser Website zu unterstützen und für andere Zwecke, die in unserer Datenschutzrichtlinie beschrieben werden.",
        "privacy policy": "Datenschutzrichtlinie",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Hinweise zu Ihrer Bestellung, z. B. besondere Hinweise zur Lieferung.",
        "Shipping Information": "Informationen zum Versand",

        //tnc
        "I have read and agree to the website": "Ich habe die Website gelesen und bin mit den",
        "terms and conditions": "Bedingungen und Konditionen",

        //Add to cart
        "Add to cart": "In den Warenkorb",

        //customerReviews
        "Rating and reviews": "Bewertung und Rezensionen",
        "Based on": "Basierend auf",
        "reviews": "Bewertungen",
        "Show more": "Mehr anzeigen",
        "Your email address will not be published.": "Ihre E-Mail Adresse wird nicht veröffentlicht.",
        "Required fields are marked": "Erforderliche Felder sind markiert",
        "Your rating": "Ihre Bewertung",
        "Your review": "Ihre Bewertung",
        "Name": "Name",
        "Submit": "Abschicken",
        "Write review": "Bewertung schreiben",
        "Add a review": "Eine Bewertung hinzufügen",

        //navigation
        "Home": "Startseite",

        //productAccordion
        "Description": "Beschreibung",
        "Reviews": "Bewertungen",

        //selectProductOrder
        "Popularity": "Popularität",
        "Average rating": "Durchschnittliche Bewertung",
        "Latest": "Letzte",
        "Price - low to high": "Preis - niedrig bis hoch",
        "Price - high to low": "Preis - hoch bis niedrig",

        //selectReviewOrder
        "Newest": "Neuestes",
        "Highest rated": "Höchste Bewertung",
        "Lowest rated": "Am niedrigsten bewertet",
        "Oldest": "Älteste",
        "Sort by": "Sortieren nach",

        //categoriesSection
        "Shop by Category": "Einkaufen nach Kategorie",
        "Show more categories": "Weitere Kategorien anzeigen",

        //reviews section
        "Google reviews": "Google Bewertungen",
        "What Ortorex ™ Customers Say": "Was Ortorex ™ Kunden Sagen",

        //topSalesSection
        "Ortorex Top Sellers": "Ortorex Top-Verkäufer",

        //customerServiceLinks
        "Customer service": "Kundenservice",

        //footer
        "Other Countries": "Andere Länder",
        "Sign Up For Exclusive Offers": "Registrieren Sie sich für exklusive Angebote",

        //informationLinks
        "Information": "Informationen",

        //paymentLogos
        "Secure payments": "Sichere Zahlungen",

        //burgerMenuClose
        "Close main menu": "Hauptmenü schließen",
        "Open main menu": "Hauptmenü öffnen",

        //cookies
        "Decline": "Ablehnen",
        "Metrics:": "Metriken",
        "Privacy Overview": "Überblick über den Datenschutz",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Diese Website verwendet Cookies, um Ihre Navigation auf der Website zu verbessern. Von diesen Cookies werden die als notwendig eingestuften Cookies in Ihrem Browser gespeichert, da sie für das Funktionieren der Grundfunktionen der Website unerlässlich sind. Wir verwenden auch Cookies von Dritten, die uns helfen zu analysieren und zu verstehen, wie Sie diese Website nutzen. Diese Cookies werden nur mit Ihrer Zustimmung in Ihrem Browser gespeichert. Sie haben auch die Möglichkeit, diese Cookies abzulehnen. Das Ablehnen einiger dieser Cookies kann jedoch Auswirkungen auf Ihr Surfverhalten haben.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': "NextRoll Inc. („NextRoll“) und unsere Werbepartner verwenden Cookies und ähnliche Technologien auf dieser Website und im Internet, um persönliche Daten (z. B. Ihre IP-Adresse) zu sammeln und zu nutzen. Wenn Sie zustimmen, können die Cookies, Gerätekennungen oder andere Informationen auf Ihrem Gerät zu den unten beschriebenen Zwecken gespeichert oder abgerufen werden. Sie können auf „Alle Zulassen“ oder „Alle Ablehnen“ oder oben auf Einstellungen klicken, um Ihre Zustimmung anzupassen.",
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': "NextRoll und unsere Werbepartner verarbeiten personenbezogene Daten, um: ● Informationen auf einem Gerät zu speichern und/oder darauf zuzugreifen; ● ein personalisiertes Inhaltsprofil zu erstellen; ● personalisierte Inhalte auszuwählen; ● personalisierte Werbung anzuwenden, Werbemessung durchzuführen und Einblicke in die Zielgruppe zu gewinnen; ● die Produktentwicklung zu fördern. Für einige der oben genannten Zwecke verwenden unsere Werbepartner genaue Geolokalisierungsdaten. Einige unserer Partner stützen sich auf ihre legitimen Geschäftsinteressen, um personenbezogene Daten zu verarbeiten. Sehen Sie sich unsere Werbepartner an, um zu erfahren, für welche Zwecke diese glauben, ein berechtigtes Interesse zu haben, und wie Sie einer solchen Verarbeitung widersprechen können.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": "Die von Ihnen auf dieser Website vorgenommenen Änderungen werden im gesamten Internet auf alle digitalen Objekte übertragen, die Sie mithilfe der Technologie von NextRoll aufrufen. Wenn Sie „Alle Ablehnen“ wählen, können Sie weiterhin Inhalte auf dieser Website ansehen und erhalten weiterhin Werbung, die jedoch nicht auf Sie zugeschnitten ist. Sie können Ihre Einstellung jederzeit ändern.",
        "Show less": "Weniger anzeigen",
        "Necessary": "Notwendig",
        "Preferences": "Präferenzen",
        "Statistics": "Statistik",
        "Marketing": "Marketing",
        "Always Enabled": "Immer Aktiviert",
        "Enabled": "Aktiviert",
        "Disabled": "Behinderte",
        "Save and accept": "SPEICHERN & ANNEHMEN",
        "Cookie settings": "Mehr erfahren",
        "Accept": "AKZEPTIEREN",
        "Accept all": "Alle akzeptieren",
        "We value your privacy": "Wir schätzen Ihre Privatsphäre",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": "Diese Website verwendet Cookies, um Ihr Erlebnis zu verbessern. Wir gehen davon aus, dass Sie damit einverstanden sind, aber Sie können sich dagegen entscheiden, wenn Sie es wünschen.",
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Notwendige Cookies sind für das ordnungsgemäße Funktionieren der Website unbedingt erforderlich. Zu dieser Kategorie gehören nur Cookies, die grundlegende Funktionen und Sicherheitsmerkmale der Website gewährleisten. Diese Cookies speichern keine persönlichen Informationen.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Präferenz-Cookies ermöglichen es einer Website, Informationen zu speichern, die das Verhalten oder Aussehen der Website verändern, wie z. B. Ihre bevorzugte Sprache oder die Region, in der Sie sich befinden.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Statistik-Cookies helfen Website-Betreibern zu verstehen, wie Besucher mit Websites interagieren, indem sie Informationen anonym sammeln und melden.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Marketing-Cookies werden verwendet, um Besucher auf verschiedenen Websites zu verfolgen. Ziel ist es, Anzeigen zu schalten, die für den einzelnen Nutzer relevant und ansprechend sind und damit für Verlage und dritte Werbetreibende wertvoller sind.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Sie haben sich erfolgreich für unsere exklusiven Angebote angemeldet!",
        "Unexpected error occurred! Please try again later.": "Unerwarteter Fehler aufgetreten! Bitte versuchen Sie es später noch einmal.",
        "Sign up for exclusive offers": "Registrieren Sie sich für exklusive Angebote",
        "Enter your email": "Ihre E-Mail eingeben",
        "Sign up": "Anmelden",

        //loadingSpinner
        "Loading...": "Laden...",

        //reviewCard
        "Read more...": "Mehr lesen...",

        //searchCombobox
        "No products were found matching your selection.": "Es wurden keine Produkte zu Ihrer Auswahl gefunden.",

        //searchInput
        "Search for...": "Suche nach...",
        "I am looking for...": "Ich bin auf der Suche nach...",

        //404
        "Oops, ": "Huch, ",
        "nothing": "nichts",
        " here...": " hier...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "Wir können die Seite, die Sie suchen, nicht finden. Versuchen Sie, zur vorherigen Seite zurückzukehren.",
        "Go back": "Zurückgehen",
        "Not found": "Nicht gefunden",

        //cart
        "Cart updated.": "Warenkorb aktualisiert.",
        "Return to shop": "Zurück zum Shop",
        "Cart": "Warenkorb",
        "Your cart": "Ihr Warenkorb",
        "Continue shopping": "Weiter einkaufen",
        "Price": "Preis",
        "Quantity": "Menge",
        "Coupon:": "Coupon:",

        //checkout
        "Color: ": "Farbe: ",
        "Size: ": "Größe: ",

        //shop
        "Search": "Suche",
        "Shop": "Laden",
        "Sort by: ": "Sortieren nach: ",

        //product
        "You may also like": "Sie könnten sich auch interessieren für",
        "Be sure to check the size in our": "Bitte überprüfen Sie die Größe in unserer",
        "Size Guide": "Größentabelle",
        "This item is now sold out and unavailable": "Dieser Artikel ist jetzt ausverkauft und nicht mehr verfügbar",
        "Email me when available": "E-Mail, wenn verfügbar",
        "Your Email": "Ihre E-Mail",
        "Send": "Senden",
        "Up to 20% Off Your First Purchase": "Bis zu 20% Rabatt auf Ihren ersten Einkauf",
        "Get Coupon": "Coupon erhalten",
        "Only": "Nur",
        "left in stock": "noch auf Lager",
        "Free shipping for all orders": "Kostenloser Versand für alle Bestellungen",
        "bought in past month": "im letzten Monat gekauft",

        //store
        "Coupon applied successfully!": "Coupon erfolgreich angewendet!",
        "Unknown error": "Unbekannter Fehler",
        "Coupon removed successfully!": "Coupon erfolgreich entfernt!",

        //discounts
        "Thank you for shopping with us": "Danke, dass Sie bei uns einkaufen",
        "20%": "20%",
        "discount within 20 minutes": "Rabatt innerhalb von 20 Minuten",
        "Select products": "Produkte auswählen",
        "Any product with a 20% discount": "Jedes Produkt mit 20% Rabatt",
        "Your discount expires in:": "Ihr Rabatt läuft ab in",
        "hours": "Stunden",
        "minutes": "Minuten",
        "seconds": "Sekunden",
        "Time's up!": "Die Zeit ist um!",
    },
    'es': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Tratamiento ortopédico de los pies a la cabeza",

        // other countries footer
        "United States": "Estados Unidos",
        "Canada": "Canadá",
        "Ireland": "Irlanda",
        "New Zealand": "Nueva Zelanda",
        "Germany": "Alemania",
        "Spain": "España",
        "Switzerland": "Suiza",
        "France": "Francia",
        "United Kingdom": "Reino Unido",
        "Australia": "Australia",
        "Austria": "Austria",
        "Portugal": "Portugal",
        "Sweden": "Suecia",
        "Norway": "Noruega",
        "Finland": "Finlandia",
        "Denmark": "Dinamarca",
        "Italy": "Italia",
        "Netherlands": "Países Bajos",
        "Belgium": "Bélgica",
        "Czech Republic": "República Checa",
        "Hungary": "Hungría",
        "Romania": "Rumanía",
        "Greece": "Grecia",

        //header offer
        "Sale Ends": "Fin de la venta",
        "20-50% OFF+Free Shipping": "20-50% de descuento envío gratis",

        //success page
        "Thank you!": "Gracias.",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Hemos recibido su pedido. Acabamos de enviarle un correo electrónico de confirmación. Sólo tiene que ir a su bandeja de entrada y buscar el correo electrónico. Esté atento, porque el correo de confirmación puede acabar en su carpeta de correo no deseado.",
        "Order details": "Datos del pedido",
        "Billing address": "Dirección de facturación",
        "Shipping address": "Dirección de envío",
        "Order Number": "Número de pedido",
        "Date": "Fecha",
        "Email": "Correo electrónico",
        "Total": "Total",
        "Payment method": "Forma de pago",
        "Subtotal": "Subtotal",
        "Shipping": "Envío",
        "Product": "Producto",

        //applyCoupon
        "Coupon ": "El cupón",
        " is applied successfully.": " se ha aplicado correctamente.",
        "Coupon code": "Código del cupón",
        "Apply code": "Aplicar código",
        "Remove": "Eliminar",

        //cartSidebar
        "cart": "carrito",
        "Shop now": "Comprar ahora",
        "View cart": "Ver carrito",
        "Includes": "Incluye",

        //checkout options
        "Checkout": "Pedido",
        "Or": "O",
        "Express checkout": "Pago exprés",
        "Have a promo code? Click here.": "¿Tiene un código promocional? Haga clic aquí.",
        "Coupon": "Cupón",
        "applied!": "aplicado",
        "Payment": "Pago",
        "All transaction are secure and encrypted.": "Todas las transacciones son seguras y están encriptadas",
        "Select the address that matches your card or payment method.": "Selecciona la dirección que coincida con tu tarjeta o método de pago",
        "Same as shipping address": "La misma que la dirección de envío",
        "Use a different billing address": "Utilice una dirección de facturación diferente",
        "Show order summary": "Mostrar resumen del pedido",
        "Hide order summary": "Ocultar resumen del pedido",

        //checkout errors
        "This value is required.": "Este valor es obligatorio",
        "Must only contain digits 0 through 9.": "Sólo debe contener dígitos del 0 al 9",
        "Invalid ZIP code.": "Código postal no válido",
        "Billing address is not filled in": "La dirección de facturación no está rellenada",
        "Invalid email.": "Correo electrónico no válido.",

        //empty cart
        "Your cart is currently empty!": "Su cesta está vacía.",
        "Looks like you have no items in your shopping cart.": "Parece que no tiene artículos en su cesta de la compra.",
        "Return to home page": "Volver a la página de inicio",

        //checkout bottom
        "Satisfaction": "Satisfacción",
        "Guaranteed": "Garantizada",
        "Free": "Gratis",
        "Exchange": "Intercambio",
        "Customer": "Cliente",
        "Service": "Atención al cliente",

        //addressInputs
        "First name": "Nombre de pila",
        "Last name": "Apellidos",
        "Street address": "Dirección postal",
        "Apartment, suite, etc. (optional)": "Apartamento, suite, etc. (opcional)",
        "City": "Ciudad",
        "Country / Region": "País / Región",
        "State": "Estado",
        "Post Code / ZIP": "Código postal",
        "Phone": "Teléfono",

        //checkout buttons 
        "Continue to shipping": "Continuar con el envío",
        "Return to cart": "Volver al carrito",
        "Continue to payment": "Continuar con el pago",
        "Return to information": "Volver a la información",
        "Complete order": "Completar pedido",
        "Return to shipping": "Volver al envío",

        //information form
        "Email me with news and offers": "Envíeme un correo electrónico con noticias y ofertas",

        //payment form
        "Billing Information": "Información de facturación",
        "Use a different billing address?": "¿Utilizar una dirección de facturación diferente?",

        //payment options
        "Credit Card (Stripe)": "Tarjeta de crédito (Stripe)",
        "Pay with your credit card via Stripe.": "Pague con su tarjeta de crédito a través de Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Pague a través de PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Sus datos personales serán utilizados para procesar su pedido, apoyar su experiencia a través de este sitio web, y para otros fines descritos en nuestra",
        "privacy policy": "política de privacidad",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Notas sobre su pedido, por ejemplo, notas especiales para la entrega.",
        "Shipping Information": "Información de envío",

        //tnc
        "I have read and agree to the website": "He leído y acepto las",
        "terms and conditions": "términos y condiciones",

        //Add to cart
        "Add to cart": "Añadir al carrito",

        //customerReviews
        "Rating and reviews": "Valoración y reseñas",
        "Based on": "Basado en",
        "reviews": "opiniones",
        "Show more": "Ver más",
        "Your email address will not be published.": "Su dirección de correo electrónico no será publicada.",
        "Required fields are marked": "Los campos obligatorios están marcados",
        "Your rating": "Su valoración",
        "Your review": "Su opinión",
        "Name": "Nombre",
        "Submit": "Enviar",
        "Write review": "Escriba su opinión",
        "Add a review": "Añadir un comentario",

        //navigation
        "Home": "Inicio",

        //productAccordion
        "Description": "Descripción",
        "Reviews": "Reseñas",

        //selectProductOrder
        "Popularity": "Popularidad",
        "Average rating": "Valoración media",
        "Latest": "Última",
        "Price - low to high": "Precio - de menor a mayor",
        "Price - high to low": "Precio - de mayor a menor",

        //selectReviewOrder
        "Newest": "Más reciente",
        "Highest rated": "Mejor valorado",
        "Lowest rated": "Calificación más baja",
        "Oldest": "Más antiguo",
        "Sort by": "Ordenar por",

        //categoriesSection
        "Shop by Category": "Comprar por categoría",
        "Show more categories": "Mostrar más categorías",

        //reviews section
        "Google reviews": "Reseñas en Google",
        "What Ortorex ™ Customers Say": "Lo Que Dicen Los Clientes De Ortorex ™",

        //topSalesSection
        "Ortorex Top Sellers": "Los Más Vendidos De Ortorex",

        //customerServiceLinks
        "Customer service": "Servicio de atención al cliente",

        //footer
        "Other Countries": "Otros países",
        "Sign Up For Exclusive Offers": "Ofertas Exclusivas",

        //informationLinks
        "Information": "Información",

        //paymentLogos
        "Secure payments": "Pagos seguros",

        //burgerMenuClose
        "Close main menu": "Cerrar menú principal",
        "Open main menu": "Abrir menú principal",

        //cookies
        "Decline": "Rechazar",
        "Metrics:": "Métricas:",
        "Privacy Overview": "Política de privacidad",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Este sitio web utiliza cookies para mejorar su experiencia mientras navega por él. De estas cookies, las que se clasifican como necesarias se almacenan en su navegador, ya que son esenciales para el funcionamiento de las funciones básicas del sitio web. También utilizamos cookies de terceros que nos ayudan a analizar y comprender cómo utiliza este sitio web. Estas cookies se almacenarán en su navegador sólo con su consentimiento. También tiene la opción de excluirse de estas cookies. Sin embargo, la exclusión voluntaria de algunas de estas cookies puede afectar a su experiencia de navegación.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': 'NextRoll, Inc. ("NextRoll") y nuestros socios publicitarios utilizan cookies y tecnologías similares en este sitio y en toda la web para recopilar y utilizar datos personales (por ejemplo, su dirección IP). Si usted da su consentimiento, las cookies, los identificadores de dispositivo u otra información pueden almacenarse o acceder en su dispositivo para los fines descritos a continuación. Puede hacer clic en "Permitir Todo" o "Rechazar Todo" o hacer clic en Configuración más arriba para personalizar su consentimiento.',
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': " NextRoll y nuestros socios publicitarios procesan datos personales para: ● Almacenar y/o acceder a información en un dispositivo; ● Crear un perfil de contenido personalizado; ● Seleccionar contenido personalizado; ● Anuncios personalizados, medición de anuncios y perspectivas de la audiencia; ● Desarrollo de productos. Para algunos de los fines anteriores, nuestros socios publicitarios: ● Utilizan datos precisos de geolocalización. Algunos de nuestros socios se basan en sus intereses comerciales legítimos para procesar datos personales. Consulte nuestros socios publicitarios para ver los fines para los que creen tener un interés legítimo y cómo puede oponerse a dicho procesamiento.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": " Sus cambios en este sitio se aplicarán a través de Internet en cualquier propiedad digital que visite utilizando la tecnología de NextRoll. Si selecciona Rechazar Todo, podrá seguir viendo el contenido de este sitio y seguirá recibiendo publicidad, pero la publicidad no estará adaptada a usted. Puede cambiar su configuración siempre que vea",
        "Show less": "Mostrar menos",
        "Necessary": "Necesarias",
        "Preferences": "Preferencias",
        "Statistics": "Estadísticas",
        "Marketing": "Marketing",
        "Always Enabled": "Siempre Activado",
        "Enabled": "Activado",
        "Disabled": "Discapacitados",
        "Save and accept": "GUARDAR Y ACEPTAR",
        "Cookie settings": "Configuración de las cookies",
        "Accept": "ACEPTAR",
        "Accept all": "Aceptar todo",
        "We value your privacy": "Valoramos su intimidad",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": 'Utilizamos cookies en nuestro sitio web para ofrecerle la experiencia más relevante al recordar sus preferencias y visitas repetidas. Al hacer clic en "Aceptar", consiente el uso de TODAS las cookies.',
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Las cookies necesarias son absolutamente imprescindibles para que el sitio web funcione correctamente. Esta categoría sólo incluye cookies que garantizan funcionalidades básicas y características de seguridad del sitio web. Estas cookies no almacenan ninguna información personal.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Las cookies de preferencias permiten que un sitio web recuerde información que modifica el comportamiento o el aspecto del mismo, como su idioma preferido o la región en la que se encuentra.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Las cookies estadísticas ayudan a los propietarios de sitios web a comprender cómo interactúan los visitantes con los sitios web mediante la recopilación y notificación de información de forma anónima.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Las cookies de marketing se utilizan para hacer un seguimiento de los visitantes de los sitios web. La intención es mostrar anuncios que sean relevantes y atractivos para el usuario individual y, por lo tanto, más valiosos para los editores y los anunciantes de terceros.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "¡Se has suscrito con éxito a nuestras ofertas exclusivas!",
        "Unexpected error occurred! Please try again later.": "Se ha producido un error inesperado. Vuelva a intentarlo más tarde.",
        "Sign up for exclusive offers": "Regístrese para recibir ofertas exclusivas",
        "Enter your email": "Introduzca su dirección de correo electrónico",
        "Sign up": "Regístrate",

        //loadingSpinner
        "Loading...": "Cargando...",

        //reviewCard
        "Read more...": "Leer más...",

        //searchCombobox
        "No products were found matching your selection.": "No se han encontrado productos que coincidan con su selección.",

        //searchInput
        "Search for...": "Buscar...",
        "I am looking for...": "Estoy buscando...",

        //404
        "Oops, ": "Ups, ",
        "nothing": "aquí",
        " here...": "no hay nada...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "No encontramos la página que busca. Intente volver a la página anterior.",
        "Go back": "Volver atrás",
        "Not found": "No se ha encontrado",

        //cart
        "Cart updated.": "Carrito actualizado.",
        "Return to shop": "Volver a la tienda",
        "Cart": "Cesta",
        "Your cart": "Su cesta",
        "Continue shopping": "Seguir comprando",
        "Price": "Precio",
        "Quantity": "Cantidad",
        "Coupon:": "Cupón:",

        //checkout
        "Color: ": "Color:",
        "Size: ": "Talla:",

        //shop
        "Search": "Buscar en",
        "Shop": "Tienda",
        "Sort by: ": "Ordenar por: ",

        //product
        "You may also like": "También le puede gustar",
        "Be sure to check the size in our": "Asegúrese de comprobar la talla en nuestra",
        "Size Guide": "Guía de tallas",
        "This item is now sold out and unavailable": "Este artículo está agotado y no está disponible",
        "Email me when available": "Envíeme un correo electrónico cuando esté disponible",
        "Your Email": "Su email",
        "Send": "Enviar",
        "Up to 20% Off Your First Purchase": "Hasta un 20% de descuento en su primera compra",
        "Get Coupon": "Obtener cupón",
        "Only": "Sólo",
        "left in stock": "en stock",
        "Free shipping for all orders": "Envío gratuito para todos los pedidos",
        "bought in past month": "comprados en el último mes",

        //store
        "Coupon applied successfully!": "¡Cupón aplicado correctamente!",
        "Unknown error": "Error desconocido",
        "Coupon removed successfully!": "¡Cupón eliminado correctamente!",

        //discounts
        "Thank you for shopping with us": "Gracias por comprar con nosotros",
        "20%": "20%",
        "discount within 20 minutes": "descuento en 20 minutos",
        "Select products": "Seleccionar productos",
        "Any product with a 20% discount": "Cualquier producto con un 20% de descuento",
        "Your discount expires in:": "Su descuento caduca en",
        "hours": "horas",
        "minutes": "minutos",
        "seconds": "segundos",
        "Time's up!": "¡Se acabó el tiempo!",
    },
    'fr': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Traitement orthopédique de la tête aux pieds",

        // other countries footer
        "United States": "États-Unis",
        "Canada": "Le Canada",
        "Ireland": "L'Irlande",
        "New Zealand": "Nouvelle Zélande",
        "Germany": "Allemagne",
        "Spain": "Espagne",
        "Switzerland": "Suisse",
        "France": "France",
        "United Kingdom": "Royaume-Uni",
        "Australia": "Australie",
        "Austria": "Autriche",
        "Portugal": "Portugal",
        "Sweden": "Suède",
        "Norway": "Norvège",
        "Finland": "Finlande",
        "Denmark": "Danemark",
        "Italy": "Italie",
        "Netherlands": "Pays-Bas",
        "Belgium": "Belgique",
        "Czech Republic": "République Tchèque",
        "Hungary": "Hongrie",
        "Romania": "Roumanie",
        "Greece": "Grèce",

        //header offer
        "Sale Ends": "Fin de la vente",
        "20-50% OFF+Free Shipping": "20-50% de réduction livraison gratuite",

        //success page
        "Thank you!": "Merci!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Votre commande a bien été reçue. Nous venons de vous envoyer un courriel de confirmation. Allez dans votre boîte de réception et cherchez l'e-mail. Soyez vigilant, car l'e-mail de confirmation peut se retrouver dans votre dossier spam.",
        "Order details": "Détails de la commande",
        "Billing address": "Adresse de facturation",
        "Shipping address": "Adresse de livraison",
        "Order Number": "Numéro de commande",
        "Date": "Date de la commande",
        "Email": "Courriel",
        "Total": "Total de la commande",
        "Payment method": "Mode de paiement",
        "Subtotal": "Sous-total",
        "Shipping": "Expédition",
        "Product": "Produit",

        //applyCoupon
        "Coupon ": "Le coupon ",
        " is applied successfully.": " est appliqué avec succès.",
        "Coupon code": "Code du coupon",
        "Apply code": "Appliquer le code",
        "Remove": "Supprimer",

        //cartSidebar
        "cart": "panier",
        "Shop now": "Acheter maintenant",
        "View cart": "Voir le panier",
        "Includes": "Inclut",

        //checkout options
        "Checkout": "Encaissement",
        "Or": "Ou",
        "Express checkout": "Paiement express",
        "Have a promo code? Click here.": "Vous avez un code promo ? Cliquez ici.",
        "Coupon": "Coupon",
        "applied!": "appliqué !",
        "Payment": "Paiement",
        "All transaction are secure and encrypted.": "Toutes les transactions sont sécurisées et cryptées.",
        "Select the address that matches your card or payment method.": "Sélectionnez l'adresse qui correspond à votre carte ou à votre mode de paiement.",
        "Same as shipping address": "Identique à l'adresse de livraison",
        "Use a different billing address": "Utiliser une autre adresse de facturation",
        "Show order summary": "Résumé de l'ordre d'affichage",
        "Hide order summary": "Cacher le résumé de la commande",

        //checkout errors
        "This value is required.": "Cette valeur est obligatoire.",
        "Must only contain digits 0 through 9.": "Doit contenir uniquement les chiffres de 0 à 9.",
        "Invalid ZIP code.": "Code postal non valide.",
        "Billing address is not filled in": "L'adresse de facturation n'est pas renseignée",
        "Invalid email.": "Courriel non valide.",

        //empty cart
        "Your cart is currently empty!": "Votre panier est actuellement vide!",
        "Looks like you have no items in your shopping cart.": "Il semble que vous n'ayez aucun article dans votre panier.",
        "Return to home page": "Retour à la page d'accueil",

        //checkout bottom
        "Satisfaction": "Satisfaction",
        "Guaranteed": "Garantie",
        "Free": "Gratuit",
        "Exchange": "Échange",
        "Customer": "Service client",
        "Service": "Service après-vente",

        //addressInputs
        "First name": "Prénom",
        "Last name": "Nom de famille",
        "Street address": "Adresse (rue)",
        "Apartment, suite, etc. (optional)": "Appartement, suite, etc.",
        "City": "Ville",
        "Country / Region": "Pays / Région",
        "State": "Etat",
        "Post Code / ZIP": "Code postal",
        "Phone": "Téléphone",

        //checkout buttons 
        "Continue to shipping": "Continuer à expédier",
        "Return to cart": "Retourner au panier",
        "Continue to payment": "Continuer le paiement",
        "Return to information": "Retourner aux informations",
        "Complete order": "Terminer la commande",
        "Return to shipping": "Retourner à l'expédition",

        //information form
        "Email me with news and offers": "Envoyez-moi des nouvelles et des offres par courrier électronique",

        //payment form
        "Billing Information": "Informations sur la facturation",
        "Use a different billing address?": "Utiliser une autre adresse de facturation ?",

        //payment options
        "Credit Card (Stripe)": "Carte de crédit (Stripe)",
        "Pay with your credit card via Stripe.": "Payez avec votre carte de crédit via Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Payez via PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Vos données personnelles seront utilisées pour traiter votre commande, faciliter votre expérience sur ce site web et à d'autres fins décrites dans notre",
        "privacy policy": "politique de confidentialité",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Notes concernant votre commande, par exemple des notes spéciales pour la livraison.",
        "Shipping Information": "Informations sur l'expédition",

        //tnc
        "I have read and agree to the website": "J'ai lu et j'accepte les",
        "terms and conditions": "conditions d'utilisation du site web",

        //Add to cart
        "Add to cart": "Ajouter au panier",

        //customerReviews
        "Rating and reviews": "Évaluation et commentaires",
        "Based on": "Basé sur les",
        "reviews": "commentaires",
        "Show more": "Plus d'informations",
        "Your email address will not be published.": "Votre adresse e-mail ne sera pas publiée.",
        "Required fields are marked": "Les champs obligatoires sont marqués",
        "Your rating": "Votre note",
        "Your review": "Votre avis",
        "Name": "Nom de l'utilisateur",
        "Submit": "Soumettre",
        "Write review": "Rédiger un commentaire",
        "Add a review": "Ajouter un commentaire",

        //navigation
        "Home": "Accueil",

        //productAccordion
        "Description": "Description du produit",
        "Reviews": "Critiques",

        //selectProductOrder
        "Popularity": "Popularité",
        "Average rating": "Note moyenne",
        "Latest": "Dernière",
        "Price - low to high": "Prix - du plus bas au plus haut",
        "Price - high to low": "Prix - du plus haut au plus bas",

        //selectReviewOrder
        "Newest": "Le plus récent",
        "Highest rated": "Le plus élevé",
        "Lowest rated": "Le moins bien noté",
        "Oldest": "Le plus ancien",
        "Sort by": "Trier par",

        //categoriesSection
        "Shop by Category": "Acheter par catégorie",
        "Show more categories": "Afficher plus de catégories",

        //reviews section
        "Google reviews": "Avis de Google",
        "What Ortorex ™ Customers Say": "Ce que disent les clients d'Ortorex ™",

        //topSalesSection
        "Ortorex Top Sellers": "Les meilleures ventes d'Ortorex",

        //customerServiceLinks
        "Customer service": "Service client",

        //footer
        "Other Countries": "Autres pays",
        "Sign Up For Exclusive Offers": "S'inscrire pour des offres exclusives",

        //informationLinks
        "Information": "Information",

        //paymentLogos
        "Secure payments": "Paiements sécurisés",

        //burgerMenuClose
        "Close main menu": "Fermer le menu principal",
        "Open main menu": "Ouvrir le menu principal",

        //cookies
        "Decline": "Refuser",
        "Metrics:": "Mesures:",
        "Privacy Overview": "Vue d'ensemble de la protection de la vie privée",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Ce site web utilise des cookies pour améliorer votre expérience lorsque vous naviguez sur le site. Parmi ces cookies, ceux qui sont considérés comme nécessaires sont stockés sur votre navigateur, car ils sont essentiels au fonctionnement des fonctionnalités de base du site web. Nous utilisons également des cookies de tiers qui nous aident à analyser et à comprendre la manière dont vous utilisez ce site web. Ces cookies ne seront stockés dans votre navigateur qu'avec votre consentement. Vous avez également la possibilité de refuser ces cookies. Cependant, le fait de refuser certains de ces cookies peut avoir un effet sur votre expérience de navigation.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': ` NextRoll, Inc. ("NextRoll") et nos partenaires publicitaires utilisent des cookies et des technologies similaires sur ce site et sur le web pour collecter et utiliser des données personnelles (par exemple, votre adresse IP). Si vous y consentez, les cookies, les identificateurs d'appareil ou d'autres informations peuvent être stockés ou consultés sur votre appareil aux fins décrites ci-dessous. Vous pouvez cliquer sur "Autoriser Tout" ou "Refuser Tout" ou cliquer sur Paramètres ci-dessus pour personnaliser votre consentement.`,
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': " NextRoll et nos partenaires publicitaires traitent les données personnelles pour : ● Stocker et/ou accéder à des informations sur un appareil ; ● Créer un profil de contenu personnalisé ; ● Sélectionner un contenu personnalisé ; ● Annonces personnalisées, mesure des annonces et aperçu de l'audience ; ● Développement de produits. Pour certaines des finalités ci-dessus, nos partenaires publicitaires : ● Utilisent des données de géolocalisation précises. Certains de nos partenaires s'appuient sur leurs intérêts commerciaux légitimes pour traiter les données personnelles. Consultez nos partenaires publicitaires pour voir les finalités pour lesquelles ils estiment avoir un intérêt légitime et comment vous pouvez vous opposer à ce traitement.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": ` Les modifications que vous apportez à ce site seront appliquées à travers l'internet sur toute propriété numérique que vous visitez en utilisant la technologie de NextRoll. Si vous sélectionnez "Tout Refuser", vous pourrez toujours consulter le contenu de ce site et vous recevrez toujours de la publicité, mais celle-ci ne sera pas adaptée à vos besoins. Vous pouvez modifier vos paramètres chaque fois que vous voyez`,
        "Show less": "Montrer moins",
        "Necessary": "Nécessaire",
        "Preferences": "Préférences",
        "Statistics": "Statistiques",
        "Marketing": "Marketing",
        "Always Enabled": "Toujours Activé",
        "Enabled": "Activé",
        "Disabled": "Handicapés",
        "Save and accept": "SAUVEGARDER ET ACCEPTER",
        "Cookie settings": "Paramètres des cookies",
        "Accept": "ACCEPTER",
        "Accept all": "Accepter tous",
        "We value your privacy": "Nous accordons de l'importance à votre vie privée",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": "Ce site web utilise des cookies pour améliorer votre expérience. Nous supposerons que vous êtes d'accord avec cela, mais vous pouvez vous désabonner si vous le souhaitez. ",
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Les cookies nécessaires sont absolument indispensables au bon fonctionnement du site web. Cette catégorie ne comprend que les cookies qui garantissent les fonctionnalités de base et les caractéristiques de sécurité du site web. Ces cookies ne stockent aucune information personnelle.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Les cookies de préférence permettent à un site web de mémoriser des informations qui modifient le comportement ou l'apparence du site, comme votre langue préférée ou la région dans laquelle vous vous trouvez.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Les cookies statistiques aident les propriétaires de sites web à comprendre comment les visiteurs interagissent avec les sites web en collectant et en rapportant des informations de manière anonyme.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Les cookies marketing sont utilisés pour suivre les visiteurs sur les sites web. L'objectif est d'afficher des publicités pertinentes et attrayantes pour l'utilisateur individuel et donc plus intéressantes pour les éditeurs et les annonceurs tiers.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Vous vous êtes inscrit avec succès à nos offres exclusives !",
        "Unexpected error occurred! Please try again later.": "Une erreur inattendue s'est produite ! Veuillez réessayer plus tard.",
        "Sign up for exclusive offers": "S'inscrire aux offres exclusives",
        "Enter your email": "Saisissez votre adresse électronique",
        "Sign up": "S'inscrire",

        //loadingSpinner
        "Loading...": "Chargement...",

        //reviewCard
        "Read more...": "Lire plus...",

        //searchCombobox
        "No products were found matching your selection.": "Aucun produit ne correspond à votre sélection.",

        //searchInput
        "Search for...": "Rechercher...",
        "I am looking for...": "Je cherche...",

        //404
        "Oops, ": "Oups.., ",
        "nothing": "rien",
        " here...": " ici...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "Nous ne parvenons pas à trouver la page que vous recherchez. Essayez de revenir à la page précédente.",
        "Go back": "Retourner à la page précédente",
        "Not found": "Non trouvé",

        //cart
        "Cart updated.": "Panier mis à jour.",
        "Return to shop": "Retourner à la boutique",
        "Cart": "Panier",
        "Your cart": "Votre panier",
        "Continue shopping": "Poursuivre les achats",
        "Price": "Prix de vente",
        "Quantity": "Quantité",
        "Coupon:": "Coupon:",

        //checkout
        "Color: ": "Couleur: ",
        "Size: ": "Taille: ",

        //shop
        "Search": "Rechercher",
        "Shop": "Boutique",
        "Sort by: ": "Trier par: ",

        //product
        "You may also like": "Vous pouvez aussi aimer",
        "Be sure to check the size in our": "Vérifiez la taille dans notre",
        "Size Guide": "Guide des tailles",
        "This item is now sold out and unavailable": "Cet article est actuellement épuisé et indisponible",
        "Email me when available": "Envoyez-moi un courriel lorsque l'article est disponible",
        "Your Email": "Votre adresse e-mail",
        "Send": "Envoyer",
        "Up to 20% Off Your First Purchase": "Jusqu'à 20 % de réduction sur votre premier achat",
        "Get Coupon": "Obtenir un coupon",
        "Only": "Seulement",
        "left in stock": "en stock",
        "Free shipping for all orders": "Livraison gratuite pour toutes les commandes",
        "bought in past month": "achetées au cours du dernier mois",

        //store
        "Coupon applied successfully!": "Coupon appliqué avec succès!",
        "Unknown error": "Erreur inconnue",
        "Coupon removed successfully!": "Coupon supprimé avec succès!",

        //discounts
        "Thank you for shopping with us": "Merci d'avoir fait vos achats chez nous",
        "20%": "20%",
        "discount within 20 minutes": "réduction dans les 20 minutes",
        "Select products": "Sélectionner les produits",
        "Any product with a 20% discount": "Tous les produits avec une réduction de 20%",
        "Your discount expires in:": "Votre remise expire dans",
        "hours": "heures",
        "minutes": "minutes",
        "seconds": "secondes",
        "Time's up!": "Le temps est écoulé !",
    },
    'pt': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Tratamento ortopédico da cabeça aos pés",

        // other countries footer
        "United States": "Estados Unidos",
        "Canada": "Canadá",
        "Ireland": "Irlanda",
        "New Zealand": "Nova Zelândia",
        "Germany": "Alemanha",
        "Spain": "Espanha",
        "Switzerland": "Suíça",
        "France": "França",
        "United Kingdom": "Reino Unido",
        "Australia": "Austrália",
        "Austria": "Áustria",
        "Portugal": "Portugal",
        "Sweden": "Suécia",
        "Norway": "Noruega",
        "Finland": "Finlândia",
        "Denmark": "Dinamarca",
        "Italy": "Itália",
        "Netherlands": "Países Baixos",
        "Belgium": "Bélgica",
        "Czech Republic": "República Checa",
        "Hungary": "Hungria",
        "Romania": "Roménia",
        "Greece": "Grécia",

        //header offer
        "Sale Ends": "Fim da venda",
        "20-50% OFF+Free Shipping": "20-50% OFF Frete Grátis",

        //success page
        "Thank you!": "Obrigado!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "A sua encomenda foi recebida. Acabámos de lhe enviar um e-mail de confirmação. Basta ir à sua caixa de e-mail e procurar a mensagem. Fique atento, pois o e-mail de confirmação pode acabar na sua pasta de spam.",
        "Order details": "Detalhes da encomenda",
        "Billing address": "Endereço de faturação",
        "Shipping address": "Endereço de envio",
        "Order Number": "Número da encomenda",
        "Date": "Data",
        "Email": "E-mail",
        "Total": "Valor total da encomenda",
        "Payment method": "Método de pagamento",
        "Subtotal": "Subtotal",
        "Shipping": "Envio",
        "Product": "Produto",

        //applyCoupon
        "Coupon ": "O cupom",
        " is applied successfully.": " aplicado com sucesso.",
        "Coupon code": "Código do cupom",
        "Apply code": "Aplicar código",
        "Remove": "Remover",

        //cartSidebar
        "cart": "carrinho",
        "Shop now": "Comprar agora",
        "View cart": "Ver carrinho",
        "Includes": "Incluir",

        //checkout options
        "Checkout": "Finalizar compra",
        "Or": "Ou",
        "Express checkout": "Finalização rápida da compra",
        "Have a promo code? Click here.": "Tem um código promocional? Clique aqui.",
        "Coupon": "Cupão",
        "applied!": "aplicado!",
        "Payment": "Pagamento",
        "All transaction are secure and encrypted.": "Todas as transacções são seguras e encriptadas.",
        "Select the address that matches your card or payment method.": "Seleccione o endereço que corresponde ao seu cartão ou método de pagamento.",
        "Same as shipping address": "Igual ao endereço de envio",
        "Use a different billing address": "Utilizar um endereço de faturação diferente",
        "Show order summary": "Mostrar resumo da encomenda",
        "Hide order summary": "Ocultar resumo da encomenda",

        //checkout errors
        "This value is required.": "Este valor é obrigatório.",
        "Must only contain digits 0 through 9.": "Só pode conter os dígitos 0 a 9.",
        "Invalid ZIP code.": "Código postal inválido.",
        "Billing address is not filled in": "O endereço de faturação não está preenchido",
        "Invalid email.": "Correio eletrónico inválido.",

        //empty cart
        "Your cart is currently empty!": "O seu carrinho de compras está vazio!",
        "Looks like you have no items in your shopping cart.": "Parece que não tem artigos no seu carrinho de compras.",
        "Return to home page": "Voltar à página inicial",

        //checkout bottom
        "Satisfaction": "Satisfação",
        "Guaranteed": "Garantida",
        "Free": "Grátis",
        "Exchange": "Troca",
        "Customer": "Atendimento ao cliente",
        "Service": "Serviço ao Cliente",

        //addressInputs
        "First name": "Nome próprio",
        "Last name": "Sobrenome",
        "Street address": "Rua",
        "Apartment, suite, etc. (optional)": "Apartamento, suíte, etc. (facultativo)",
        "City": "Cidade",
        "Country / Region": "País / Cidade",
        "State": "Estado",
        "Post Code / ZIP": "Código Postal / CEP",
        "Phone": "Telefone",

        //checkout buttons 
        "Continue to shipping": "Ir para a tela de envio",
        "Return to cart": "Voltar ao carrinho",
        "Continue to payment": "Continuar para o pagamento",
        "Return to information": "Voltar às informações",
        "Complete order": "Concluir a encomenda",
        "Return to shipping": "Regressar ao envio",

        //information form
        "Email me with news and offers": "Enviar-me um e-mail com novidades e ofertas",

        //payment form
        "Billing Information": "Informações de faturação",
        "Use a different billing address?": "Utilizar um endereço de e-mail diferente?",

        //payment options
        "Credit Card (Stripe)": "Cartão de crédito (Stripe)",
        "Pay with your credit card via Stripe.": "Pague com o seu cartão de crédito através do Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Pagar através de PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Os seus dados pessoais serão utilizados para processar a sua encomenda, melhorar a sua experiência neste site e para outros fins descritos na nossa",
        "privacy policy": "política de privacidade",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Notas sobre a sua encomenda, por exemplo, notas especiais para a entrega.",
        "Shipping Information": "Informações de envio",

        //tnc
        "I have read and agree to the website": "Li e concordo com os",
        "terms and conditions": "termos e condições",

        //Add to cart
        "Add to cart": "Adicionar ao carrinho",

        //customerReviews
        "Rating and reviews": "Classificação e avaliações",
        "Based on": "Com base em",
        "reviews": "comentários",
        "Show more": "Mostrar mais",
        "Your email address will not be published.": "O seu endereço de e-mail não será publicado.",
        "Required fields are marked": "Os campos obrigatórios estão marcados",
        "Your rating": "A sua classificação",
        "Your review": "Sua avaliação",
        "Name": "Nome de usuário",
        "Submit": "Enviar",
        "Write review": "Escrever comentário",
        "Add a review": "Adicionar um comentário",

        //navigation
        "Home": "Início",

        //productAccordion
        "Description": "Descrição do produto",
        "Reviews": "Comentários",

        //selectProductOrder
        "Popularity": "Popularidade",
        "Average rating": "Classificação média",
        "Latest": "Último",
        "Price - low to high": "Preço - do mais barato para o mais caro",
        "Price - high to low": "Preço - do mais alto para o mais barato",

        //selectReviewOrder
        "Newest": "Mais recente",
        "Highest rated": "Melhor classificado",
        "Lowest rated": "Classificação mais baixa",
        "Oldest": "Mais antigo",
        "Sort by": "Ordenar por",

        //categoriesSection
        "Shop by Category": "Comprar por categoria",
        "Show more categories": "Mostrar mais categorias",

        //reviews section
        "Google reviews": "Comentários do Google",
        "What Ortorex ™ Customers Say": "O que os clientes da Ortorex ™ dizem",

        //topSalesSection
        "Ortorex Top Sellers": "Os mais vendidos da Ortorex",

        //customerServiceLinks
        "Customer service": "Serviço ao cliente",

        //footer
        "Other Countries": "Outros países",
        "Sign Up For Exclusive Offers": "Inscrever-se Para Ofertas Exclusivas",

        //informationLinks
        "Information": "Informação",

        //paymentLogos
        "Secure payments": "Pagamentos seguros",

        //burgerMenuClose
        "Close main menu": "Fechar o menu principal",
        "Open main menu": "Abrir o menu principal",

        //cookies
        "Decline": "Recusar",
        "Metrics:": "Métricas:",
        "Privacy Overview": "Visão geral da privacidade",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Este site utiliza cookies para melhorar a sua experiência enquanto navega. Desses cookies, os cookies que são categorizados como necessários são armazenados no seu navegador, uma vez que são essenciais para o funcionamento das funcionalidades básicas do website. Também utilizamos cookies de terceiros que nos ajudam a analisar e a compreender a forma como o usuário utiliza o nosso site. Esses cookies serão armazenados no seu browser apenas com o seu consentimento. O utilizador também tem a opção de recusar esses cookies. No entanto, a desativação de alguns deles pode afetar a sua experiência de navegação.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': 'A NextRoll, Inc. ("NextRoll") e os nossos parceiros de publicidade utilizam cookies e tecnologias semelhantes neste site e em toda a Web para recolher e utilizar dados pessoais (por exemplo, o seu endereço IP). Se der o seu consentimento, os cookies, identificadores de dispositivos ou outras informações podem ser armazenados ou acedidos no seu dispositivo para os fins descritos abaixo. É possível clicar em "Permitir Tudo" ou "Recusar Tudo", ou clicar em Definições acima para personalizar o seu consentimento.',
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': "A NextRoll e nossos parceiros de publicidade processam dados pessoais para: ● Armazenar e/ou aceder a informações num dispositivo; ● Criar um perfil de conteúdo personalizado; ● Selecionar conteúdo personalizado; ● Anúncios personalizados, medição de anúncios e insights de público; ● Desenvolvimento de produtos. Para algumas das finalidades acima, nossos parceiros de publicidade: ● Usam dados precisos de geolocalização. Alguns dos nossos parceiros baseiam-se nos seus interesses comerciais legítimos para processar dados pessoais. Consulte os nossos parceiros de publicidade para ver as finalidades para as quais acreditam ter um interesse e como se pode opor a esse processamento.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": "As suas alterações neste site serão aplicadas em toda a Internet em qualquer propriedade digital que visite utilizando a tecnologia da NextRoll. Se selecionar Recusar Tudo, continuará a poder ver o conteúdo deste site e continuará a receber publicidade, mas esta não será adaptada ao usuário. O utilizador pode alterar a sua definição sempre que vir",
        "Show less": "Mostrar menos",
        "Necessary": "Necessários",
        "Preferences": "Preferências",
        "Statistics": "Estatísticas",
        "Marketing": "Marketing",
        "Always Enabled": "Sempre ativado",
        "Enabled": "Ativado",
        "Disabled": "Desativado",
        "Save and accept": "GUARDAR E ACEITAR",
        "Cookie settings": "Definições de biscoitos",
        "Accept": "ACEITAR TUDO",
        "Accept all": "Aceitar tudo",
        "We value your privacy": "Valorizamos a sua privacidade",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": "Colocámos cookies no seu computador. Os nossos cookies são utilizados para personalizar o conteúdo e a publicidade.",
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Os cookies necessários são absolutamente essenciais para que o website funcione corretamente. Essa categoria inclui apenas os cookies que asseguram as funcionalidades básicas e as características de segurança da web. Esses cookies não armazenam quaisquer informações pessoais.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Os cookies de preferências permitem que um sítio Web memorize informações que alteram o comportamento ou o aspeto do sítio Web, como o idioma preferido ou a região em que se encontra.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Os cookies estatísticos ajudam os proprietários de sítios Web a compreender a forma como os visitantes interagem com os sítios Web, recolhendo e comunicando informações de forma anónima.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Os cookies de marketing são utilizados para rastrear os visitantes nos sítios Web. A intenção é apresentar anúncios que sejam relevantes e interessantes para o utilizador individual e, por conseguinte, mais valiosos para os editores e anunciantes terceiros.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Você se inscreveu com sucesso para receber as nossas ofertas exclusivas!",
        "Unexpected error occurred! Please try again later.": "Ocorreu um erro inesperado! Tente novamente mais tarde.",
        "Sign up for exclusive offers": "Inscrever-se para ofertas exclusivas",
        "Enter your email": "Introduza o seu e-mail",
        "Sign up": "Registar",

        //loadingSpinner
        "Loading...": "Carregando...",

        //reviewCard
        "Read more...": "Ler mais...",

        //searchCombobox
        "No products were found matching your selection.": "Não foram encontrados produtos que correspondam à sua busca.",

        //searchInput
        "Search for...": "Procurar por...",
        "I am looking for...": "Estou à procura de...",

        //404
        "Oops, ": "Oops, ",
        "nothing": "nada",
        " here...": " aqui...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "Parece que não conseguimos encontrar a página que você estava procurando. Tente voltar à página anterior.",
        "Go back": "Voltar atrás",
        "Not found": "Não encontrado",

        //cart
        "Cart updated.": "Carrinho atualizado.",
        "Return to shop": "Voltar à loja",
        "Cart": "Carrinho",
        "Your cart": "O seu carrinho",
        "Continue shopping": "Continuar comprando",
        "Price": "Preço",
        "Quantity": "Quantidade",
        "Coupon:": "Cupom:",

        //checkout
        "Color: ": "Cor: ",
        "Size: ": "Tamanho: ",

        //shop
        "Search": "Procurar",
        "Shop": "Loja",
        "Sort by: ": "Ordenar por: ",

        //product
        "You may also like": "Você também pode gostar",
        "Be sure to check the size in our": "Não se esqueça de verificar o tamanho no nosso produto",
        "Size Guide": "Guia de tamanhos",
        "This item is now sold out and unavailable": "Este item está esgotado e indisponível",
        "Email me when available": "Enviar um e-mail quando voltar a ficar disponível",
        "Your Email": "O seu e-mail",
        "Send": "Enviar",
        "Up to 20% Off Your First Purchase": "Até 20% De Desconto Na Sua Primeira Compra",
        "Get Coupon": "Obter cupom",
        "Only": "Apenas",
        "left in stock": "em stock",
        "Free shipping for all orders": "Envio gratuito para todas as encomendas",
        "bought in past month": "comprados no mês passado",

        //store
        "Coupon applied successfully!": "Cupom aplicado com sucesso!",
        "Unknown error": "Erro desconhecido",
        "Coupon removed successfully!": "Cupom removido com sucesso!",

        //discounts
        "Thank you for shopping with us": "Obrigado por comprar connosco",
        "20%": "20%",
        "discount within 20 minutes": "desconto em 20 minutos",
        "Select products": "Selecionar produtos",
        "Any product with a 20% discount": "Qualquer produto com 20% de desconto",
        "Your discount expires in:": "O seu desconto expira em",
        "hours": "horas",
        "minutes": "minutos",
        "seconds": "segundos",
        "Time's up!": "O tempo acabou!",
    },
    'it': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Trattamento ortopedico dalla testa ai piedi",

        // other countries footer
        "United States": "Stati Uniti",
        "Canada": "Canada",
        "Ireland": "Irlanda",
        "New Zealand": "Nuova Zelanda",
        "Germany": "Germania",
        "Spain": "Spagna",
        "Switzerland": "Svizzera",
        "France": "Francia",
        "United Kingdom": "Regno Unito",
        "Australia": "Australia",
        "Austria": "Austria",
        "Portugal": "Portogallo",
        "Sweden": "Svezia",
        "Norway": "Norvegia",
        "Finland": "Finlandia",
        "Denmark": "Danimarca",
        "Italy": "Italia",
        "Netherlands": "Paesi Bassi",
        "Belgium": "Belgio",
        "Czech Republic": "Repubblica Ceca",
        "Hungary": "Ungheria",
        "Romania": "Romania",
        "Greece": "Grecia",

        //header offer
        "Sale Ends": "La vendita termina",
        "20-50% OFF+Free Shipping": "20-50% DI SCONTO+Spedizione gratuita",

        //success page
        "Thank you!": "Grazie!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Il suo ordine è stato ricevuto. Le abbiamo appena inviato un'e-mail di conferma. Vada alla sua casella di posta elettronica e cerchi l'e-mail. Attenzione, perché la mail di conferma potrebbe finire nella cartella spam.",
        "Order details": "Dettagli dell'ordine",
        "Billing address": "Indirizzo di fatturazione",
        "Shipping address": "Indirizzo di spedizione",
        "Order Number": "Numero d'ordine",
        "Date": "Data dell'ordine",
        "Email": "Email",
        "Total": "Totale",
        "Payment method": "Metodo di pagamento",
        "Subtotal": "Totale parziale",
        "Shipping": "Spedizione",
        "Product": "Prodotto",

        //applyCoupon
        "Coupon ": "Il coupon ",
        " is applied successfully.": " è stato applicato con successo.",
        "Coupon code": "Codice coupon",
        "Apply code": "Applica il codice",
        "Remove": "Rimuovere",

        //cartSidebar
        "cart": "Carrello",
        "Shop now": "Acquista ora",
        "View cart": "Visualizza carrello",
        "Includes": "Include",

        //checkout options
        "Checkout": "Cassa",
        "Or": "Oppure",
        "Express checkout": "Cassa veloce",
        "Have a promo code? Click here.": "Avete un codice promozionale? Fare clic qui.",
        "Coupon": "Coupon",
        "applied!": "applicato!",
        "Payment": "Pagamento",
        "All transaction are secure and encrypted.": "Tutte le transazioni sono sicure e criptate",
        "Select the address that matches your card or payment method.": "Selezionare l'indirizzo che corrisponde alla carta o al metodo di pagamento",
        "Same as shipping address": "Stesso indirizzo di spedizione",
        "Use a different billing address": "Utilizza un indirizzo di fatturazione diverso",
        "Show order summary": "Mostra il riepilogo dell'ordine",
        "Hide order summary": "Nascondi il riepilogo dell'ordine",

        //checkout errors
        "This value is required.": "Questo valore è obbligatorio",
        "Must only contain digits 0 through 9.": "Deve contenere solo cifre da 0 a 9",
        "Invalid ZIP code.": "Codice postale non valido",
        "Billing address is not filled in": "L'indirizzo di fatturazione non è stato compilato",
        "Invalid email.": "Email non valida.",

        //empty cart
        "Your cart is currently empty!": "Il carrello è attualmente vuoto!",
        "Looks like you have no items in your shopping cart.": "Sembra che non ci siano articoli nel carrello.",
        "Return to home page": "Torna alla pagina iniziale",

        //checkout bottom
        "Satisfaction": "Soddisfazione",
        "Guaranteed": "Garantita",
        "Free": "Gratuito",
        "Exchange": "Scambio",
        "Customer": "Servizio clienti",
        "Service": "Servizio",

        //addressInputs
        "First name": "Nome",
        "Last name": "Cognome",
        "Street address": "Indirizzo",
        "Apartment, suite, etc. (optional)": "Appartamento, suite, ecc. (opzionale)",
        "City": "Città",
        "Country / Region": "Paese / Regione",
        "State": "Stato",
        "Post Code / ZIP": "Codice postale / CAP",
        "Phone": "Telefono",

        //checkout buttons 
        "Continue to shipping": "Continua con la spedizione",
        "Return to cart": "Torna al carrello",
        "Continue to payment": "Vai al pagamento",
        "Return to information": "Torna alle informazioni",
        "Complete order": "Completa l'ordine",
        "Return to shipping": "Ritorno alla spedizione",

        //information form
        "Email me with news and offers": "Inviami un'e-mail con le novità e le offerte",

        //payment form
        "Billing Information": "Informazioni sulla fatturazione",
        "Use a different billing address?": "Utilizzare un altro indirizzo di fatturazione?",

        //payment options
        "Credit Card (Stripe)": "Carta di credito (Stripe)",
        "Pay with your credit card via Stripe.": "Pagate con la vostra carta di credito tramite Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Pagare con PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "I vostri dati personali saranno utilizzati per elaborare il vostro ordine, supportare la vostra esperienza su questo sito web e per altri scopi descritti nella nostra ",
        "privacy policy": "informativa sulla privacy",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Note sul vostro ordine, ad esempio note speciali per la consegna.",
        "Shipping Information": "Informazioni sulla spedizione",

        //tnc
        "I have read and agree to the website": "Ho letto e accetto i termini e le condizioni del sito",
        "terms and conditions": "termini e condizioni",

        //Add to cart
        "Add to cart": "Aggiungi al carrello",

        //customerReviews
        "Rating and reviews": "Valutazione e recensioni",
        "Based on": "In base a",
        "reviews": "recensioni",
        "Show more": "Mostra di più",
        "Your email address will not be published.": "Il tuo indirizzo e-mail non sarà pubblicato.",
        "Required fields are marked": "I campi obbligatori sono contrassegnati",
        "Your rating": "La tua valutazione",
        "Your review": "La tua recensione",
        "Name": "Il nome",
        "Submit": "Invia",
        "Write review": "Scrivi la recensione",
        "Add a review": "Aggiungi una recensione",

        //navigation
        "Home": "Casa",

        //productAccordion
        "Description": "Descrizione del prodotto",
        "Reviews": "Recensioni",

        //selectProductOrder
        "Popularity": "Popolarità",
        "Average rating": "Valutazione media",
        "Latest": "Ultimo",
        "Price - low to high": "Prezzo - da basso a alto",
        "Price - high to low": "Prezzo - da alto a basso",

        //selectReviewOrder
        "Newest": "Il più recente",
        "Highest rated": "Il più votato",
        "Lowest rated": "Il più basso",
        "Oldest": "Il più vecchio",
        "Sort by": "Ordina per",

        //categoriesSection
        "Shop by Category": "Acquista per categoria",
        "Show more categories": "Mostra più categorie",

        //reviews section
        "Google reviews": "Recensioni di Google",
        "What Ortorex ™ Customers Say": "Cosa dicono i clienti di Ortorex ™",

        //topSalesSection
        "Ortorex Top Sellers": "I Più Venduti Di Ortorex",

        //customerServiceLinks
        "Customer service": "Servizio clienti",

        //footer
        "Other Countries": "Altri Paesi",
        "Sign Up For Exclusive Offers": "Iscriviti Per Ricevere Offerte Esclusive",

        //informationLinks
        "Information": "Informazioni",

        //paymentLogos
        "Secure payments": "Pagamenti sicuri",

        //burgerMenuClose
        "Close main menu": "Chiudere il menu principale",
        "Open main menu": "Aprire il menu principale",

        //cookies
        "Decline": "Rifiuta",
        "Metrics:": "Metriche:",
        "Privacy Overview": "Panoramica sulla Privacy",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Questo sito web utilizza i cookie per migliorare la vostra esperienza di navigazione. Tra questi cookie, quelli classificati come necessari vengono memorizzati nel browser dell'utente in quanto essenziali per le funzionalità di base del sito. Utilizziamo anche cookie di terze parti che ci aiutano ad analizzare e a capire come gli utenti utilizzano il sito. Questi cookie vengono memorizzati nel vostro browser solo con il vostro consenso. L'utente ha anche la possibilità di rinunciare a questi cookie. Tuttavia, la rinuncia ad alcuni di questi cookie potrebbe avere ripercussioni sulla vostra esperienza di navigazione.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': ` NextRoll, Inc. ("NextRoll") e i nostri partner pubblicitari utilizzano cookie e tecnologie simili su questo sito e in tutto il web per raccogliere e utilizzare dati personali (ad esempio, il vostro indirizzo IP). Se l'utente acconsente, i cookie, gli identificatori del dispositivo o altre informazioni possono essere memorizzati o consultati sul suo dispositivo per gli scopi descritti di seguito. È possibile fare clic su "Consenti tutto" o "Rifiuta tutto" oppure su Impostazioni per personalizzare il proprio consenso.`,
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': " NextRoll e i nostri partner pubblicitari trattano i dati personali per: ● memorizzare e/o accedere alle informazioni su un dispositivo; ● creare un profilo di contenuti personalizzati; ● selezionare contenuti personalizzati; ● annunci personalizzati, misurazione degli annunci e analisi del pubblico; ● sviluppo di prodotti. Per alcune delle finalità di cui sopra, i nostri partner pubblicitari: ● Utilizzano dati di geolocalizzazione precisi. Alcuni dei nostri partner si basano sui loro legittimi interessi commerciali per trattare i dati personali. Consultate i nostri partner pubblicitari per conoscere le finalità per le quali ritengono di avere un interesse legittimo e le modalità con cui potete opporvi a tale trattamento.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": ` Le modifiche da voi apportate a questo sito saranno applicate in tutto il mondo su qualsiasi proprietà digitale che visiterete, utilizzando la tecnologia di NextRoll. Se selezionate "Rifiuta tutto", potrete comunque visualizzare i contenuti di questo sito e continuerete a ricevere pubblicità, ma gli annunci non saranno personalizzati per voi. L'utente può modificare la propria impostazione ogni volta che vede`,
        "Show less": "Mostra meno",
        "Necessary": "Necessari",
        "Preferences": "Preferenze",
        "Statistics": "Statistiche",
        "Marketing": "Marketing",
        "Always Enabled": "Sempre abilitato",
        "Enabled": "Abilitato",
        "Disabled": "Disabili",
        "Save and accept": "SALVA E ACCETTA",
        "Cookie settings": "Impostazioni dei cookie",
        "Accept": "ACCETTA",
        "Accept all": "Accettare tutti",
        "We value your privacy": "Teniamo alla vostra privacy",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": `Sul nostro sito web utilizziamo i cookie per offrirvi l'esperienza più pertinente possibile, ricordando le vostre preferenze e le vostre visite frequenti. Facendo clic su "Accetta", si acconsente all'utilizzo di TUTTI i cookie.`,
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "I cookie necessari sono assolutamente indispensabili per il corretto funzionamento del sito web. Questa categoria comprende solo i cookie che garantiscono le funzionalità di base e le caratteristiche di sicurezza del sito web. Questi cookie non memorizzano alcuna informazione personale.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "I cookie di preferenza consentono a un sito web di ricordare informazioni che modificano il comportamento o l'aspetto del sito, come la lingua preferita o la regione in cui ci si trova.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "I cookie statistici aiutano i proprietari dei siti web a capire come i visitatori interagiscono con i siti web, raccogliendo e riportando informazioni in forma anonima.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "I cookie di marketing vengono utilizzati per tracciare i visitatori dei vari siti web. L'intento è quello di visualizzare annunci pertinenti e coinvolgenti per il singolo utente e quindi di maggior valore per gli editori e gli inserzionisti terzi.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Ti sei iscritto con successo alle nostre offerte esclusive!",
        "Unexpected error occurred! Please try again later.": "Si è verificato un errore inaspettato! Riprova più tardi.",
        "Sign up for exclusive offers": "Iscriviti alle offerte esclusive",
        "Enter your email": "Inserisci il tuo indirizzo e-mail",
        "Sign up": "Iscriviti",

        //loadingSpinner
        "Loading...": "Caricamento...",

        //reviewCard
        "Read more...": "Per saperne di più...",

        //searchCombobox
        "No products were found matching your selection.": "Non sono stati trovati prodotti corrispondenti alla tua selezione.",

        //searchInput
        "Search for...": "Cerca...",
        "I am looking for...": "Sto cercando...",

        //404
        "Oops, ": "Ops, ",
        "nothing": "niente",
        " here...": " qui non c'è...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "Non riusciamo a trovare la pagina che stai cercando. Prova a tornare alla pagina precedente.",
        "Go back": "Torna indietro",
        "Not found": "Non trovato",

        //cart
        "Cart updated.": "Carrello aggiornato.",
        "Return to shop": "Torna al negozio",
        "Cart": "Carrello",
        "Your cart": "Il tuo carrello",
        "Continue shopping": "Continua lo shopping",
        "Price": "Il prezzo",
        "Quantity": "Quantità",
        "Coupon:": "Coupon:",

        //checkout
        "Color: ": "Colore: ",
        "Size: ": "Taglia: ",

        //shop
        "Search": "Ricerca",
        "Shop": "Negozio",
        "Sort by: ": "Ordina per: ",

        //product
        "You may also like": "Potrebbe piacerti anche",
        "Be sure to check the size in our": "Assicurati di controllare la taglia nella nostra",
        "Size Guide": "Guida Alle Taglie",
        "This item is now sold out and unavailable": "Questo articolo è esaurito e non disponibile",
        "Email me when available": "Inviami un'e-mail quando è disponibile",
        "Your Email": "Il tuo indirizzo e-mail",
        "Send": "Invia",
        "Up to 20% Off Your First Purchase": "Fino Al 20% Di Sconto Sul Primo Acquisto",
        "Get Coupon": "Ottieni un coupon",
        "Only": "Solo",
        "left in stock": "rimasti in magazzino",
        "Free shipping for all orders": "Spedizione gratuita per tutti gli ordini",
        "bought in past month": "acquistati nell'ultimo mese",

        //store
        "Coupon applied successfully!": "Coupon applicato con successo!",
        "Unknown error": "Errore sconosciuto",
        "Coupon removed successfully!": "Coupon rimosso con successo!",

        //discounts
        "Thank you for shopping with us": "Grazie per aver acquistato con noi",
        "20%": "20%",
        "discount within 20 minutes": "sconto entro 20 minuti",
        "Select products": "Seleziona i prodotti",
        "Any product with a 20% discount": "Qualsiasi prodotto con uno sconto del 20%",
        "Your discount expires in:": "Lo sconto scade tra",
        "hours": "ore",
        "minutes": "minuti",
        "seconds": "secondi",
        "Time's up!": "Tempo scaduto!",
    },
    'sv': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Ortopedisk behandling från topp till tå",

        // other countries footer
        "United States": "Förenta staterna",
        "Canada": "Kanada",
        "Ireland": "Irland",
        "New Zealand": "Nya Zeeland",
        "Germany": "Tyskland",
        "Spain": "Spanien",
        "Switzerland": "Schweiz",
        "France": "Frankrike",
        "United Kingdom": "Förenade kungariket",
        "Australia": "Australien",
        "Austria": "Österrike",
        "Portugal": "Portugal",
        "Sweden": "Sverige",
        "Norway": "Norge",
        "Finland": "Finland",
        "Denmark": "Danmark",
        "Italy": "Italien",
        "Netherlands": "Nederländerna",
        "Belgium": "Belgien",
        "Czech Republic": "Tjeckiska republiken",
        "Hungary": "Ungern",
        "Romania": "Rumänien",
        "Greece": "Grekland",

        //header offer
        "Sale Ends": "Försäljningen slutar",
        "20-50% OFF+Free Shipping": "20-50% RABATT+Fri frakt",

        //success page
        "Thank you!": "Vi tackar för oss!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Din beställning har tagits emot. Vi har just skickat ett bekräftelsemail till dig. Gå bara till din e-postinkorg och leta efter e-postmeddelandet. Håll utkik eftersom bekräftelsemailet kan hamna i din skräppostmapp.",
        "Order details": "Uppgifter om beställningen",
        "Billing address": "Faktureringsadress",
        "Shipping address": "Leveransadress",
        "Order Number": "Beställningsnummer",
        "Date": "Datum",
        "Email": "E-post",
        "Total": "Totalt antal",
        "Payment method": "Betalningsmetod",
        "Subtotal": "Delsumma",
        "Shipping": "Frakt",
        "Product": "Produkt",

        //applyCoupon
        "Coupon ": "Kupong ",
        " is applied successfully.": " har tillämpats framgångsrikt.",
        "Coupon code": "Kupongkod",
        "Apply code": "Tillämpa kod",
        "Remove": "Ta bort",

        //cartSidebar
        "cart": "varukorg",
        "Shop now": "Handla nu",
        "View cart": "Visa varukorg",
        "Includes": "Inkluderar",

        //checkout options
        "Checkout": "Utcheckning",
        "Or": "Eller",
        "Express checkout": "Snabb utcheckning",
        "Have a promo code? Click here.": "Har du en kampanjkod? Klicka här.",
        "Coupon": "Kupong",
        "applied!": "tillämpas!",
        "Payment": "Betalning",
        "All transaction are secure and encrypted.": "Alla transaktioner är säkra och krypterade.",
        "Select the address that matches your card or payment method.": "Välj den adress som matchar ditt kort eller din betalningsmetod.",
        "Same as shipping address": "Samma som leveransadress",
        "Use a different billing address": "Använd en annan faktureringsadress",
        "Show order summary": "Sammanställning av showorder",
        "Hide order summary": "Dölj orderöversikt",

        //checkout errors
        "This value is required.": "Detta värde är obligatoriskt.",
        "Must only contain digits 0 through 9.": "Får endast innehålla siffrorna 0 till 9.",
        "Invalid ZIP code.": "Ogiltigt postnummer.",
        "Billing address is not filled in": "Faktureringsadressen är inte ifylld",
        "Invalid email.": "E-postadressen är ogiltig.",

        //empty cart
        "Your cart is currently empty!": "Din kundvagn är för närvarande tom!",
        "Looks like you have no items in your shopping cart.": "Det ser ut som om du inte har några artiklar i din kundvagn.",
        "Return to home page": "Återgå till startsidan",

        //checkout bottom
        "Satisfaction": "Nöjdhet",
        "Guaranteed": "Garanterad",
        "Free": "Gratis",
        "Exchange": "Utbyte",
        "Customer": "Kund",
        "Service": "Kundtjänst",

        //addressInputs
        "First name": "Förnamn",
        "Last name": "Efternamn",
        "Street address": "Gatuadress",
        "Apartment, suite, etc. (optional)": "Lägenhet, svit, etc. (valfritt)",
        "City": "Stad",
        "Country / Region": "Land / Region",
        "State": "Delstat",
        "Post Code / ZIP": "Postnummer / ZIP",
        "Phone": "Telefon",

        //checkout buttons 
        "Continue to shipping": "Fortsätt till frakt",
        "Return to cart": "Gå tillbaka till kundvagnen",
        "Continue to payment": "Fortsätt till betalning",
        "Return to information": "Återgå till information",
        "Complete order": "Slutför beställning",
        "Return to shipping": "Återgå till frakt",

        //information form
        "Email me with news and offers": "Mejla mig med nyheter och erbjudanden",

        //payment form
        "Billing Information": "Faktureringsinformation",
        "Use a different billing address?": "Använd en annan faktureringsadress?",

        //payment options
        "Credit Card (Stripe)": "Betalingskort (Stripe)",
        "Pay with your credit card via Stripe.": "Betala med ditt kreditkort via Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Betala via PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Dina personuppgifter kommer att användas för att behandla din beställning, stödja din upplevelse på hela denna webbplats och för andra ändamål som beskrivs i vår",
        "privacy policy": "integritetspolicy",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Anmärkningar om din beställning, t.ex. särskilda anmärkningar för leverans.",
        "Shipping Information": "Information om leverans",

        //tnc
        "I have read and agree to the website": "Jag har läst och godkänner webbplatsens",
        "terms and conditions": "villkor och bestämmelser",

        //Add to cart
        "Add to cart": "Lägg till i varukorgen",

        //customerReviews
        "Rating and reviews": "Betyg och recensioner",
        "Based on": "Baserat på",
        "reviews": "omdömen",
        "Show more": "Visa mer",
        "Your email address will not be published.": "Din e-postadress kommer inte att publiceras.",
        "Required fields are marked": "Obligatoriska fält är markerade",
        "Your rating": "Ditt betyg",
        "Your review": "Din recension",
        "Name": "Ditt namn",
        "Submit": "Skicka in",
        "Write review": "Skriv recension",
        "Add a review": "Lägg till en recension",

        //navigation
        "Home": "Hem",

        //productAccordion
        "Description": "Beskrivning av produkten",
        "Reviews": "Recensioner",

        //selectProductOrder
        "Popularity": "Popularitet",
        "Average rating": "Genomsnittligt betyg",
        "Latest": "Senaste",
        "Price - low to high": "Pris - lågt till högt",
        "Price - high to low": "Pris - högt till lågt",

        //selectReviewOrder
        "Newest": "Senaste",
        "Highest rated": "Högsta betyg",
        "Lowest rated": "Lägsta betyg",
        "Oldest": "Äldsta",
        "Sort by": "Sortera efter",

        //categoriesSection
        "Shop by Category": "Handla efter kategori",
        "Show more categories": "Visa fler kategorier",

        //reviews section
        "Google reviews": "Google recensioner",
        "What Ortorex ™ Customers Say": "Vad Ortorex ™ kunder säger",

        //topSalesSection
        "Ortorex Top Sellers": "Ortorex bästa säljare",

        //customerServiceLinks
        "Customer service": "Länkar till kundtjänst",

        //footer
        "Other Countries": "Andra länder",
        "Sign Up For Exclusive Offers": "Registrera dig för exklusiva erbjudanden",

        //informationLinks
        "Information": "Information",

        //paymentLogos
        "Secure payments": "Säkra betalningar",

        //burgerMenuClose
        "Close main menu": "Stäng huvudmenyn",
        "Open main menu": "Öppna huvudmenyn",

        //cookies
        "Decline": "Avvisa",
        "Metrics:": "Mätvärden:",
        "Privacy Overview": "Översikt över Sekretess",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Den här webbplatsen använder cookies för att förbättra din upplevelse när du navigerar på webbplatsen. Av dessa cookies lagras de cookies som kategoriseras som nödvändiga i din webbläsare eftersom de är nödvändiga för att de grundläggande funktionerna på webbplatsen ska fungera. Vi använder också cookies från tredje part som hjälper oss att analysera och förstå hur du använder den här webbplatsen. Dessa cookies lagras i din webbläsare endast med ditt samtycke. Du har också möjlighet att välja bort dessa cookies. Men att välja bort vissa av dessa cookies kan ha en effekt på din surfupplevelse.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': 'NextRoll, Inc. ("NextRoll") och våra reklampartners använder cookies och liknande tekniker på denna webbplats och runt om på webben för att samla in och använda personuppgifter (t.ex. din IP-adress). Om du samtycker kan cookies, enhetsidentifierare eller annan information lagras eller nås på din enhet för de ändamål som beskrivs nedan. Du kan klicka på "Tillåt Alla" eller "Avvisa Alla" eller klicka på Inställningar ovan för att anpassa ditt samtycke.',
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': "NextRoll och våra reklampartners behandlar personuppgifter för att: ● Lagra och/eller komma åt information på en enhet; ● Skapa en personlig innehållsprofil; ● Välja personligt innehåll; ● Personliga annonser, annonsmätning och målgruppsinsikter; ● Produktutveckling. För vissa av syftena ovan, våra reklampartners: ● Använda exakta geolokaliseringsdata. Vissa av våra partner förlitar sig på sina legitima affärsintressen för att behandla personuppgifter. Gå till våra reklampartners för att se vilka syften de anser sig ha ett berättigat intresse för och hur du kan invända mot sådan behandling.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": "Dina ändringar på den här webbplatsen kommer att tillämpas över hela internet på alla digitala fastigheter du besöker med hjälp av NextRolls teknik. Om du väljer Avvisa Alla kommer du fortfarande att kunna se innehåll på den här webbplatsen och du kommer fortfarande att få reklam, men reklamen kommer inte att vara skräddarsydd för dig. Du kan ändra din inställning när du ser",
        "Show less": "Mostrar menos",
        "Necessary": "Nödvändiga",
        "Preferences": "Inställningar",
        "Statistics": "Statistik",
        "Marketing": "Marknadsföring",
        "Always Enabled": "Sempre ativado",
        "Enabled": "Ativado",
        "Disabled": "Desativado",
        "Save and accept": "GUARDAR E ACEITAR",
        "Cookie settings": "Inställningar för cookies",
        "Accept": "ACCEPTERA",
        "Accept all": "Acceptera alla",
        "We value your privacy": "Vi värdesätter din integritet",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": 'Vi använder cookies på vår webbplats för att ge dig den mest relevanta upplevelsen genom att komma ihåg dina preferenser och återkommande besök. Genom att klicka på "Acceptera" godkänner du användningen av ALLA cookies.',
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Nödvändiga cookies är absolut nödvändiga för att webbplatsen ska fungera korrekt. Denna kategori omfattar endast cookies som säkerställer grundläggande funktioner och säkerhetsfunktioner på webbplatsen. Dessa cookies lagrar inte någon personlig information.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Preferenscookies gör det möjligt för en webbplats att komma ihåg information som ändrar hur webbplatsen fungerar eller ser ut, t.ex. vilket språk du föredrar eller vilken region du befinner dig i.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Statistiska cookies hjälper webbplatsägare att förstå hur besökare interagerar med webbplatser genom att samla in och rapportera information anonymt.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Marknadsförings cookies används för att spåra besökare på olika webbplatser. Syftet är att visa annonser som är relevanta och engagerande för den enskilda användaren och därmed mer värdefulla för utgivare och tredjepartsannonsörer.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Du har framgångsrikt registrerat dig för våra exklusiva erbjudanden!",
        "Unexpected error occurred! Please try again later.": "Ett oväntat fel inträffade! Vänligen försök igen senare.",
        "Sign up for exclusive offers": "Registrera dig för exklusiva erbjudanden",
        "Enter your email": "Ange din e-postadress",
        "Sign up": "Registrera dig",

        //loadingSpinner
        "Loading...": "Laddar...",

        //reviewCard
        "Read more...": "Läs mer...",

        //searchCombobox
        "No products were found matching your selection.": "Inga produkter hittades som matchar ditt val.",

        //searchInput
        "Search for...": "Sök efter...",
        "I am looking for...": "Jag letar efter...",

        //404
        "Oops, ": "Hoppsan, ",
        "nothing": "inget",
        " here...": " här...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "Vi kan inte hitta den sida du letar efter. Försök att gå tillbaka till föregående sida.",
        "Go back": "Gå tillbaka till föregående sida",
        "Not found": "Hittades inte",

        //cart
        "Cart updated.": "Kundvagnen är uppdaterad.",
        "Return to shop": "Återgå till butik",
        "Cart": "Vagn",
        "Your cart": "Din varukorg",
        "Continue shopping": "Fortsätt handla",
        "Price": "Pris",
        "Quantity": "Kvantitet",
        "Coupon:": "Kupong:",

        //checkout
        "Color: ": "Färg: ",
        "Size: ": "Storlek: ",

        //shop
        "Search": "Sök",
        "Shop": "Butik",
        "Sort by: ": "Sortera efter: ",

        //product
        "You may also like": "Du kanske också gillar",
        "Be sure to check the size in our": "Var noga med att kontrollera storleken i vår",
        "Size Guide": "Storleksguide",
        "This item is now sold out and unavailable": "Denna artikel är nu slutsåld och inte tillgänglig",
        "Email me when available": "Mejla mig när den är tillgänglig",
        "Your Email": "Din e-postadress",
        "Send": "Skicka",
        "Up to 20% Off Your First Purchase": "Upp till 20% rabatt på ditt första köp",
        "Get Coupon": "Hämta kupong",
        "Only": "Endast",
        "left in stock": "kvar i lager",
        "Free shipping for all orders": "Fri frakt för alla beställningar",
        "bought in past month": "sålda under den senaste månaden",

        //store
        "Coupon applied successfully!": "Kupongen har tillämpats framgångsrikt!",
        "Unknown error": "Okänt fel",
        "Coupon removed successfully!": "Kupongen har tagits bort framgångsrikt!",

        //discounts
        "Thank you for shopping with us": "Tack för att du handlar hos oss",
        "20%": "20%",
        "discount within 20 minutes": "rabatt inom 20 minuter",
        "Select products": "Välj produkter",
        "Any product with a 20% discount": "Alla produkter med 20% rabatt",
        "Your discount expires in:": "Din rabatt upphör att gälla i",
        "hours": "timmar",
        "minutes": "minuter",
        "seconds": "sekunder",
        "Time's up!": "Tiden är ute!",
    },
    'nb': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Ortopedisk behandling fra topp til tå",

        // other countries footer
        "United States": "Forente Stater",
        "Canada": "Canada",
        "Ireland": "Irland",
        "New Zealand": "New Zealand",
        "Germany": "Tyskland",
        "Spain": "Spania",
        "Switzerland": "Sveits",
        "France": "Frankrike",
        "United Kingdom": "Storbritannia",
        "Australia": "Australia",
        "Austria": "Østerrike",
        "Portugal": "Portugal",
        "Sweden": "Sverige",
        "Norway": "Norge",
        "Finland": "Finland",
        "Denmark": "Danmark",
        "Italy": "Italia",
        "Netherlands": "Nederland",
        "Belgium": "Belgia",
        "Czech Republic": "Tsjekkia",
        "Hungary": "Ungarn",
        "Romania": "Romania",
        "Greece": "Hellas",

        //header offer
        "Sale Ends": "Salget avsluttes",
        "20-50% OFF+Free Shipping": "20-50% AVSLAG+Gratis frakt",

        //success page
        "Thank you!": "Takk skal du ha!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Bestillingen din er mottatt. Vi har nettopp sendt deg en bekreftelse på e-post. Gå til innboksen din og se etter e-posten. Vær oppmerksom på at bekreftelsesmailen kan havne i søppelpostmappen din.",
        "Order details": "Detaljer om bestillingen",
        "Billing address": "Faktureringsadresse",
        "Shipping address": "Leveringsadresse",
        "Order Number": "Bestillingsnummer",
        "Date": "Dato",
        "Email": "E-post",
        "Total": "Totalt antall",
        "Payment method": "Betalingsmåte",
        "Subtotal": "Delsum",
        "Shipping": "Frakt",
        "Product": "Produkt",

        //applyCoupon
        "Coupon ": "Kupong ",
        " is applied successfully.": " er brukt med hell.",
        "Coupon code": "Kupongkode",
        "Apply code": "Bruk koden",
        "Remove": "Fjern",

        //cartSidebar
        "cart": "Handlekurv",
        "Shop now": "Handle nå",
        "View cart": "Se handlekurv",
        "Includes": "Inkluderer",

        //checkout options
        "Checkout": "Kasse",
        "Or": "Eller",
        "Express checkout": "Ekspresskassen",
        "Have a promo code? Click here.": "Har du en kampanjekode? Klikk her.",
        "Coupon": "Kupong",
        "applied!": "Anvendt!",
        "Payment": "Betaling",
        "All transaction are secure and encrypted.": "Alle transaksjoner er sikre og krypterte.",
        "Select the address that matches your card or payment method.": "Velg adressen som samsvarer med kortet eller betalingsmåten din.",
        "Same as shipping address": "Samme som leveringsadresse",
        "Use a different billing address": "Bruk en annen faktureringsadresse",
        "Show order summary": "Vis bestillingsoversikt",
        "Hide order summary": "Skjul ordresammendrag",

        //checkout errors
        "This value is required.": "Denne verdien er obligatorisk.",
        "Must only contain digits 0 through 9.": "Må bare inneholde sifrene 0 til 9.",
        "Invalid ZIP code.": "Ugyldig postnummer.",
        "Billing address is not filled in": "Faktureringsadresse er ikke fylt ut",
        "Invalid email.": "Ugyldig e-post.",

        //empty cart
        "Your cart is currently empty!": "Handlekurven din er for øyeblikket tom!",
        "Looks like you have no items in your shopping cart.": "Det ser ut til at du ikke har noen varer i handlekurven.",
        "Return to home page": "Gå tilbake til startsiden",

        //checkout bottom
        "Satisfaction": "Tilfredshet",
        "Guaranteed": "Garantert",
        "Free": "Gratis",
        "Exchange": "Bytte",
        "Customer": "Kunde",
        "Service": "Service",

        //addressInputs
        "First name": "Fornavn",
        "Last name": "Etternavn",
        "Street address": "Gateadresse",
        "Apartment, suite, etc. (optional)": "Leilighet, suite osv. (valgfritt)",
        "City": "By",
        "Country / Region": "Land / Region",
        "State": "Delstat",
        "Post Code / ZIP": "Postnummer",
        "Phone": "Telefon",

        //checkout buttons 
        "Continue to shipping": "Fortsett til forsendelse",
        "Return to cart": "Gå tilbake til handlekurven",
        "Continue to payment": "Fortsett til betaling",
        "Return to information": "Gå tilbake til informasjon",
        "Complete order": "Fullfør bestillingen",
        "Return to shipping": "Gå tilbake til frakt",

        //information form
        "Email me with news and offers": "Send meg e-post med nyheter og tilbud",

        //payment form
        "Billing Information": "Faktureringsinformasjon",
        "Use a different billing address?": "Bruker du en annen faktureringsadresse?",

        //payment options
        "Credit Card (Stripe)": "Kredittkort (Stripe)",
        "Pay with your credit card via Stripe.": "Betal med kredittkort via Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Betal via PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Personopplysningene dine vil bli brukt til å behandle bestillingen din, støtte din opplevelse på dette nettstedet og til andre formål som er beskrevet i vår",
        "privacy policy": "personvernerklæring",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Merknader om bestillingen din, f.eks. spesielle merknader for levering.",
        "Shipping Information": "Fraktinformasjon",

        //tnc
        "I have read and agree to the website": "Jeg har lest og godtar nettstedets",
        "terms and conditions": "vilkår og betingelser",

        //Add to cart
        "Add to cart": "Legg i handlekurven",

        //customerReviews
        "Rating and reviews": "Vurdering og anmeldelser",
        "Based on": "Basert på",
        "reviews": "anmeldelser",
        "Show more": "Vis mer",
        "Your email address will not be published.": "E-postadressen din vil ikke bli publisert.",
        "Required fields are marked": "Obligatoriske felt er merket",
        "Your rating": "Din vurdering",
        "Your review": "Din anmeldelse",
        "Name": "Navn",
        "Submit": "Send inn",
        "Write review": "Skriv en anmeldelse",
        "Add a review": "Legg til en anmeldelse",

        //navigation
        "Home": "Hjem",

        //productAccordion
        "Description": "Beskrivelse av produktet",
        "Reviews": "Anmeldelser",

        //selectProductOrder
        "Popularity": "Popularitet",
        "Average rating": "Gjennomsnittlig vurdering",
        "Latest": "Siste",
        "Price - low to high": "Pris - lav til høy",
        "Price - high to low": "Pris - høy til lav",

        //selectReviewOrder
        "Newest": "Nyeste",
        "Highest rated": "Høyest vurdert",
        "Lowest rated": "Lavest vurdert",
        "Oldest": "Eldst",
        "Sort by": "Sorter etter",

        //categoriesSection
        "Shop by Category": "Handle etter kategori",
        "Show more categories": "Vis flere kategorier",

        //reviews section
        "Google reviews": "Google anmeldelser",
        "What Ortorex ™ Customers Say": "Hva Ortorex™-kunder sier",

        //topSalesSection
        "Ortorex Top Sellers": "Ortorex Bestselgere",

        //customerServiceLinks
        "Customer service": "Kundeservice",

        //footer
        "Other Countries": "Andre land",
        "Sign Up For Exclusive Offers": "Registrer deg for eksklusive tilbud",

        //informationLinks
        "Information": "Informasjon",

        //paymentLogos
        "Secure payments": "Sikre betalinger",

        //burgerMenuClose
        "Close main menu": "Lukk hovedmeny",
        "Open main menu": "Åpne hovedmenyen",

        //cookies
        "Decline": "Avslå",
        "Metrics:": "Metrics:",
        "Privacy Overview": "Oversikt over Personvern",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Dette nettstedet bruker informasjonskapsler for å forbedre opplevelsen din når du navigerer på nettstedet. Av disse informasjonskapslene lagres de informasjonskapslene som kategoriseres som nødvendige i nettleseren din, ettersom de er avgjørende for at de grunnleggende funksjonene på nettstedet skal fungere. Vi bruker også tredjeparts informasjonskapsler som hjelper oss med å analysere og forstå hvordan du bruker nettstedet. Disse informasjonskapslene lagres i nettleseren din kun med ditt samtykke. Du har også mulighet til å velge bort disse informasjonskapslene. Men hvis du velger bort noen av disse informasjonskapslene, kan det påvirke nettleseropplevelsen din.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': ' NextRoll, Inc. ("NextRoll") og våre annonsepartnere bruker informasjonskapsler og lignende teknologier på dette nettstedet og rundt om på nettet for å samle inn og bruke personopplysninger (f.eks. IP-adressen din). Hvis du samtykker, kan informasjonskapsler, enhetsidentifikatorer eller annen informasjon lagres eller åpnes på enheten din for de formålene som er beskrevet nedenfor. Du kan klikke på "Tillat Alle" eller "Avvis Alle" eller klikke på Innstillinger ovenfor for å tilpasse samtykket ditt.',
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': " NextRoll og våre annonsepartnere behandler personopplysninger for å: ● Lagre og/eller få tilgang til informasjon på en enhet; ● Opprette en personlig innholdsprofil; ● Velge personlig innhold; ● Personlige annonser, annonsemåling og publikumsinnsikt; ● Produktutvikling. For noen av formålene ovenfor bruker våre annonsepartnere: ● Bruke nøyaktige geolokaliseringsdata. Noen av våre partnere baserer seg på sine legitime forretningsinteresser for å behandle personopplysninger. Se våre annonsepartnere for å se hvilke formål de mener de har en berettiget interesse i, og hvordan du kan motsette deg slik behandling.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": " Endringene du gjør på dette nettstedet, vil bli brukt på alle digitale nettsteder du besøker, ved hjelp av NextRolls teknologi. Hvis du velger Avvis Alt, vil du fortsatt kunne se innhold på dette nettstedet, og du vil fortsatt motta reklame, men reklamen vil ikke være skreddersydd for deg. Du kan endre innstillingene dine når du ser",
        "Show less": "Vis mindre",
        "Necessary": "Nødvendige",
        "Preferences": "Innstillinger",
        "Statistics": "Statistikk",
        "Marketing": "Markedsføring",
        "Always Enabled": "Alltid aktivert",
        "Enabled": "Aktivert",
        "Disabled": "Funksjonshemmet",
        "Save and accept": "LAGRE OG ACCEPTERE",
        "Cookie settings": "Cookie-innstillinger",
        "Accept": "AKSEPTERER",
        "Accept all": "Godta alle",
        "We value your privacy": "Vi verdsetter personvernet ditt",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": 'Vi bruker informasjonskapsler på nettstedet vårt for å gi deg den mest relevante opplevelsen ved å huske dine preferanser og gjentatte besøk. Ved å klikke "Godta", samtykker du til bruken av ALLE informasjonskapslene.',
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Nødvendige informasjonskapsler er helt avgjørende for at nettstedet skal fungere som det skal. Denne kategorien omfatter kun informasjonskapsler som sikrer grunnleggende funksjoner og sikkerhetsfunksjoner på nettstedet. Disse informasjonskapslene lagrer ingen personlig informasjon.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Preferansecookies gjør det mulig for et nettsted å huske informasjon som endrer måten nettstedet oppfører seg eller ser ut på, for eksempel hvilket språk du foretrekker eller hvilken region du befinner deg i.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Statistikkcookies hjelper nettstedseiere med å forstå hvordan besøkende samhandler med nettsteder ved å samle inn og rapportere informasjon anonymt.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Informasjonskapsler for markedsføring brukes til å spore besøkende på tvers av nettsteder. Hensikten er å vise annonser som er relevante og engasjerende for den enkelte bruker og dermed mer verdifulle for utgivere og tredjepartsannonsører.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Du har registrert deg for våre eksklusive tilbud!",
        "Unexpected error occurred! Please try again later.": "Det oppstod en uventet feil! Vennligst prøv igjen senere.",
        "Sign up for exclusive offers": "Registrer deg for eksklusive tilbud",
        "Enter your email": "Skriv inn e-postadressen din",
        "Sign up": "Registrer deg",

        //loadingSpinner
        "Loading...": "Lasting...",

        //reviewCard
        "Read more...": "Les mer...",

        //searchCombobox
        "No products were found matching your selection.": "Ingen produkter ble funnet som samsvarer med ditt valg.",

        //searchInput
        "Search for...": "Søk etter...",
        "I am looking for...": "Jeg ser etter...",

        //404
        "Oops, ": "Hoppsan, ",
        "nothing": "ingenting",
        " here...": " her...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "Vi kan ikke finne siden du leter etter. Prøv å gå tilbake til forrige side.",
        "Go back": "Gå tilbake",
        "Not found": "Ikke funnet",

        //cart
        "Cart updated.": "Handlekurven er oppdatert.",
        "Return to shop": "Gå tilbake til butikken",
        "Cart": "Handlekurv",
        "Your cart": "Din handlekurv",
        "Continue shopping": "Fortsett å handle",
        "Price": "Pris",
        "Quantity": "Antall",
        "Coupon:": "Kupong:",

        //checkout
        "Color: ": "Farge:",
        "Size: ": "Størrelse:",

        //shop
        "Search": "Søk i",
        "Shop": "Butikk",
        "Sort by: ": "Sorter etter:",

        //product
        "You may also like": "Du vil kanskje også like",
        "Be sure to check the size in our": "Husk å sjekke størrelsen i vår",
        "Size Guide": "Størrelsesguide",
        "This item is now sold out and unavailable": "Denne varen er nå utsolgt og utilgjengelig",
        "Email me when available": "Send meg en e-post når den er tilgjengelig",
        "Your Email": "E-postadressen din",
        "Send": "Send",
        "Up to 20% Off Your First Purchase": "Opptil 20 % rabatt på ditt første kjøp",
        "Get Coupon": "Få kupong",
        "Only": "Bare",
        "left in stock": "igjen på lager",
        "Free shipping for all orders": "Gratis frakt for alle bestillinger",
        "bought in past month": "kjøpt i løpet av den siste måneden",

        //store
        "Coupon applied successfully!": "Kupongen ble brukt med suksess!",
        "Unknown error": "Ukjent feil",
        "Coupon removed successfully!": "Kupongen er fjernet!",

        //discounts
        "Thank you for shopping with us": "Takk for at du handler hos oss",
        "20%": "20%",
        "discount within 20 minutes": "rabatt innen 20 minutter",
        "Select products": "Velg produkter",
        "Any product with a 20% discount": "Alle produkter med 20 % rabatt",
        "Your discount expires in:": "Rabatten utløper i",
        "hours": "timer",
        "minutes": "minutter",
        "seconds": "sekunder",
        "Time's up!": "Tiden er ute!",
    },
    'fi': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - ortopedinen hoito päästä varpaisiin saakka",

        // other countries footer
        "United States": "Yhdysvallat",
        "Canada": "Kanada",
        "Ireland": "Irlanti",
        "New Zealand": "Uusi-Seelanti",
        "Germany": "Saksa",
        "Spain": "Espanja",
        "Switzerland": "Sveitsi",
        "France": "Ranska",
        "United Kingdom": "Yhdistynyt kuningaskunta",
        "Australia": "Australia",
        "Austria": "Itävalta",
        "Portugal": "Portugali",
        "Sweden": "Ruotsi",
        "Norway": "Norja",
        "Finland": "Suomi",
        "Denmark": "Tanska",
        "Italy": "Italia",
        "Netherlands": "Alankomaat",
        "Belgium": "Belgia",
        "Czech Republic": "Tšekin tasavalta",
        "Hungary": "Unkari",
        "Romania": "Romania",
        "Greece": "Kreikka",

        //header offer
        "Sale Ends": "Myynti päättyy",
        "20-50% OFF+Free Shipping": "20-50% alennus ilmainen toimitus",

        //success page
        "Thank you!": "Kiitos!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Tilauksesi on vastaanotettu. Olemme juuri lähettäneet sinulle vahvistussähköpostin. Mene sähköpostilaatikkoosi ja etsi sähköpostiviesti. Ole varuillasi, sillä vahvistussähköposti saattaa päätyä roskapostikansioosi.",
        "Order details": "Tilauksen tiedot",
        "Billing address": "Laskutusosoite",
        "Shipping address": "Toimitusosoite",
        "Order Number": "Tilausnumero",
        "Date": "Päivämäärä",
        "Email": "Sähköposti",
        "Total": "Yhteensä",
        "Payment method": "Maksutapa",
        "Subtotal": "Välisumma",
        "Shipping": "Toimitus",
        "Product": "Tuote",

        //applyCoupon
        "Coupon ": "Kuponki ",
        " is applied successfully.": " on sovellettu onnistuneesti.",
        "Coupon code": "Kuponkikoodi",
        "Apply code": "Käytä koodia",
        "Remove": "Poista",

        //cartSidebar
        "cart": "ostoskori",
        "Shop now": "Osta nyt",
        "View cart": "Näytä ostoskori",
        "Includes": "Sisältää",

        //checkout options
        "Checkout": "Kassa",
        "Or": "Tai",
        "Express checkout": "Pikakassa",
        "Have a promo code? Click here.": "Onko sinulla tarjouskoodi? Klikkaa tästä.",
        "Coupon": "Kuponki",
        "applied!": "applied!",
        "Payment": "Payment",
        "All transaction are secure and encrypted.": "Kaikki maksutapahtumat ovat turvallisia ja salattuja.",
        "Select the address that matches your card or payment method.": "Valitse osoite, joka vastaa korttiasi tai maksutapaa.",
        "Same as shipping address": "Sama kuin toimitusosoite",
        "Use a different billing address": "Käytä eri laskutusosoitetta",
        "Show order summary": "Näytä tilauksen yhteenveto",
        "Hide order summary": "Piilota tilauksen yhteenveto",

        //checkout errors
        "This value is required.": "Tämä arvo on pakollinen.",
        "Must only contain digits 0 through 9.": "Saa sisältää vain numeroita 0-9.",
        "Invalid ZIP code.": "Virheellinen postinumero.",
        "Billing address is not filled in": "Laskutusosoitetta ei ole täytetty",
        "Invalid email.": "Virheellinen sähköpostiosoite.",

        //empty cart
        "Your cart is currently empty!": "Ostoskorisi on tällä hetkellä tyhjä!",
        "Looks like you have no items in your shopping cart.": "Näyttää siltä, että sinulla ei ole tuotteita ostoskorissasi.",
        "Return to home page": "Palaa etusivulle",

        //checkout bottom
        "Satisfaction": "Tyytyväisyys",
        "Guaranteed": "Taattu",
        "Free": "Ilmainen",
        "Exchange": "Vaihto",
        "Customer": "Asiakas",
        "Service": "Palvelu",

        //addressInputs
        "First name": "Etunimi",
        "Last name": "Sukunimi",
        "Street address": "Katuosoite",
        "Apartment, suite, etc. (optional)": "Huoneisto, sviitti jne. (valinnainen)",
        "City": "Kaupunki",
        "Country / Region": "Maa / Alue",
        "State": "Osavaltio",
        "Post Code / ZIP": "Postinumero",
        "Phone": "Puhelin",

        //checkout buttons 
        "Continue to shipping": "Jatka toimitukseen",
        "Return to cart": "Palaa ostoskoriin",
        "Continue to payment": "Jatka maksamiseen",
        "Return to information": "Palaa tietoihin",
        "Complete order": "Tilauksen loppuun saattaminen",
        "Return to shipping": "Palaa toimitukseen",

        //information form
        "Email me with news and offers": "Lähetä minulle uutisia ja tarjouksia sähköpostitse",

        //payment form
        "Billing Information": "Laskutustiedot",
        "Use a different billing address?": "Käytätkö eri laskutusosoitetta?",

        //payment options
        "Credit Card (Stripe)": "Luottokortti (Stripe)",
        "Pay with your credit card via Stripe.": "Maksa luottokortilla Stripen kautta.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Maksa PayPalin kautta.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Henkilötietojasi käytetään tilauksesi käsittelyyn, kokemuksesi tukemiseen tällä verkkosivustolla ja muihin tarkoituksiin, jotka on kuvattu meidän",
        "privacy policy": "tietosuojakäytännössä",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Huomautuksia tilauksestasi, esim. erityisiä huomautuksia toimitusta varten.",
        "Shipping Information": "Toimitustiedot",

        //tnc
        "I have read and agree to the website": "Olen lukenut ja hyväksyn verkkosivuston",
        "terms and conditions": "ehdot ja edellytykset",

        //Add to cart
        "Add to cart": "Lisää ostoskoriin",

        //customerReviews
        "Rating and reviews": "Luokitus ja arvostelut",
        "Based on": "Perustuu",
        "reviews": "arvosteluihin",
        "Show more": "Näytä lisää",
        "Your email address will not be published.": "Sähköpostiosoitettasi ei julkaista.",
        "Required fields are marked": "Pakolliset kentät on merkitty",
        "Your rating": "Arvostelusi",
        "Your review": "Arvostelusi",
        "Name": "Nimi",
        "Submit": "Lähetä",
        "Write review": "Kirjoita arvostelu",
        "Add a review": "Lisää arvostelu",

        //navigation
        "Home": "Etusivu",

        //productAccordion
        "Description": "Kuvaus",
        "Reviews": "Arvostelut",

        //selectProductOrder
        "Popularity": "Suosio",
        "Average rating": "Keskimääräinen luokitus",
        "Latest": "Uusimmat",
        "Price - low to high": "Hinta - alhaisesta korkeaan",
        "Price - high to low": "Hinta - korkeasta matalaan",

        //selectReviewOrder
        "Newest": "Uusin",
        "Highest rated": "Korkeimmalle luokiteltu",
        "Lowest rated": "Alin luokiteltu",
        "Oldest": "Vanhin",
        "Sort by": "Lajittelu",

        //categoriesSection
        "Shop by Category": "Osta kategorian mukaan",
        "Show more categories": "Näytä lisää luokkia",

        //reviews section
        "Google reviews": "Google arvostelut",
        "What Ortorex ™ Customers Say": "Mitä Ortorex ™ asiakkaat sanovat",

        //topSalesSection
        "Ortorex Top Sellers": "Ortorex huippumyyjät",

        //customerServiceLinks
        "Customer service": "Asiakaspalvelu",

        //footer
        "Other Countries": "Muut Maat",
        "Sign Up For Exclusive Offers": "Rekisteröidy saadaksesi eksklusiivisia tarjouksia",

        //informationLinks
        "Information": "Tietoa",

        //paymentLogos
        "Secure payments": "Turvalliset maksut",

        //burgerMenuClose
        "Close main menu": "Sulje päävalikko",
        "Open main menu": "Avaa päävalikko",

        //cookies
        "Decline": "Hylkää",
        "Metrics:": "Mittarit:",
        "Privacy Overview": "Tietosuojaa koskeva Yleiskatsaus",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Tämä verkkosivusto käyttää evästeitä parantaakseen käyttökokemustasi, kun navigoit verkkosivustolla. Näistä evästeistä selaimeesi tallennetaan evästeet, jotka on luokiteltu välttämättömiksi, koska ne ovat välttämättömiä verkkosivuston perustoimintojen toiminnan kannalta. Käytämme myös kolmannen osapuolen evästeitä, jotka auttavat meitä analysoimaan ja ymmärtämään, miten käytät tätä verkkosivustoa. Nämä evästeet tallennetaan selaimeesi vain suostumuksellasi. Sinulla on myös mahdollisuus kieltäytyä näistä evästeistä. Joidenkin evästeiden poistaminen käytöstä voi kuitenkin vaikuttaa selailukokemukseesi.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': 'NextRoll, Inc. ("NextRoll") ja mainoskumppanimme käyttävät evästeitä ja muita vastaavia tekniikoita tällä sivustolla ja muualla verkossa henkilötietojen keräämiseen ja käyttämiseen (esim. IP-osoitteesi). Jos annat suostumuksesi, evästeitä, laitetunnisteita tai muita tietoja voidaan tallentaa tai käyttää laitteellasi alla kuvattuihin tarkoituksiin. Voit mukauttaa suostumustasi valitsemalla "Salli Kaikki" tai "Kiellä Kaikki" tai valitsemalla yllä olevat Asetukset.',
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': "NextRoll ja mainoskumppanimme käsittelevät henkilötietoja seuraavasti: ● tallentaa ja/tai käyttää tietoja laitteella; ● luoda henkilökohtaisen sisältöprofiilin; ● valita henkilökohtaista sisältöä; ● henkilökohtaisia mainoksia, mainosmittausta ja yleisötietoutta; ● tuotekehitystä. Joitakin edellä mainittuja tarkoituksia varten mainoskumppanimme: ● käyttää tarkkoja maantieteellisiä sijaintitietoja. Osa kumppaneistamme luottaa henkilötietojen käsittelyyn oikeutettujen liiketoimintaetujensa perusteella. Tutustu mainoskumppaneihimme nähdäksesi, mihin tarkoituksiin ne uskovat, että niillä on oikeutettu etu, ja miten voit vastustaa tällaista käsittelyä.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": "Tällä sivustolla tekemiäsi muutoksia sovelletaan koko internetissä kaikkiin digitaalisiin kohteisiin, joissa vierailet NextRollin teknologiaa käyttäen. Jos valitset Kieltäytyä Kaikesta, voit edelleen katsella tämän sivuston sisältöä ja saat edelleen mainontaa, mutta mainontaa ei räätälöidä sinulle sopivaksi. Voit muuttaa asetustasi aina, kun näet",
        "Show less": "Näytä vähemmän",
        "Necessary": "Tarpeellinen",
        "Preferences": "Asetukset",
        "Statistics": "Tilastot",
        "Marketing": "Markkinointi",
        "Always Enabled": "Aina käytössä",
        "Enabled": "Käytössä",
        "Disabled": "Vammaiset",
        "Save and accept": "TALLENNA & HYVÄKSY",
        "Cookie settings": "Evästeasetukset",
        "Accept": "HYVÄKSYÄ",
        "Accept all": "Hyväksy kaikki",
        "We value your privacy": "Arvostamme yksityisyyttäsi",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": 'Käytämme verkkosivustollamme evästeitä, jotta voimme tarjota sinulle mahdollisimman tarkoituksenmukaisen käyttökokemuksen muistamalla mieltymyksesi ja toistuvat vierailusi. Klikkaamalla "Hyväksyn" hyväksyt KAIKKIEN evästeiden käytön.',
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Välttämättömät evästeet ovat ehdottoman välttämättömiä, jotta verkkosivusto toimisi asianmukaisesti. Tähän kategoriaan kuuluvat vain evästeet, joilla varmistetaan verkkosivuston perustoiminnot ja turvallisuusominaisuudet. Nämä evästeet eivät tallenna mitään henkilökohtaisia tietoja.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Etusijaevästeiden avulla verkkosivusto voi muistaa tietoja, jotka muuttavat verkkosivuston käyttäytymistä tai ulkoasua, kuten haluamasi kieli tai alue, jolla olet.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Tilastoevästeet auttavat verkkosivujen omistajia ymmärtämään, miten kävijät ovat vuorovaikutuksessa verkkosivujen kanssa, keräämällä ja raportoimalla tietoja anonyymisti.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Markkinointievästeiden avulla seurataan kävijöitä eri verkkosivustoilla. Tarkoituksena on näyttää mainoksia, jotka ovat relevantteja ja kiinnostavia yksittäisen käyttäjän kannalta ja siten arvokkaampia julkaisijoille ja kolmansien osapuolten mainostajille.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Olet onnistuneesti rekisteröitynyt eksklusiivisiin tarjouksiimme!",
        "Unexpected error occurred! Please try again later.": "Odottamaton virhe tapahtui! Yritä myöhemmin uudelleen.",
        "Sign up for exclusive offers": "Tilaa yksinoikeustarjoukset",
        "Enter your email": "Kirjoita sähköpostiosoitteesi",
        "Sign up": "Rekisteröidy",

        //loadingSpinner
        "Loading...": "Ladataan...",

        //reviewCard
        "Read more...": "Lue lisää...",

        //searchCombobox
        "No products were found matching your selection.": "Valintaasi vastaavia tuotteita ei löytynyt.",

        //searchInput
        "Search for...": "Etsi...",
        "I am looking for...": "Etsin...",

        //404
        "Oops, ": "Hups, ",
        "nothing": "ei mitään",
        " here...": "täällä...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "Emme löydä etsimääsi sivua. Yritä palata edelliselle sivulle.",
        "Go back": "Siirry takaisin",
        "Not found": "Ei löydy",

        //cart
        "Cart updated.": "Ostoskori päivitetty.",
        "Return to shop": "Palaa kauppaan",
        "Cart": "Ostoskori",
        "Your cart": "Ostoskorisi",
        "Continue shopping": "Jatka ostoksia",
        "Price": "Hinta",
        "Quantity": "Määrä",
        "Coupon:": "Kuponki:",

        //checkout
        "Color: ": "Väri: ",
        "Size: ": "Koko: ",

        //shop
        "Search": "Etsi",
        "Shop": "Kauppa",
        "Sort by: ": "Lajittele: ",

        //product
        "You may also like": "Saatat myös pitää",
        "Be sure to check the size in our": "Muista tarkistaa koko meidän",
        "Size Guide": "Koko-oppaasta",
        "This item is now sold out and unavailable": "Tämä tuote on nyt loppuunmyyty ja ei ole saatavilla.",
        "Email me when available": "Lähetä minulle sähköpostia kun saatavilla",
        "Your Email": "Sähköpostiosoitteesi",
        "Send": "Lähetä",
        "Up to 20% Off Your First Purchase": "Jopa 20% alennus ensimmäisestä ostoksestasi",
        "Get Coupon": "Hanki kuponki",
        "Only": "Vain",
        "left in stock": "jäljellä varastossa",
        "Free shipping for all orders": "Ilmainen toimitus kaikille tilauksille",
        "bought in past month": "ostettu viimeisen kuukauden aikana",

        //store
        "Coupon applied successfully!": "Kuponkia sovellettu onnistuneesti!",
        "Unknown error": "Tuntematon virhe",
        "Coupon removed successfully!": "Kuponki poistettu onnistuneesti!",

        //discounts
        "Thank you for shopping with us": "Kiitos, että teet ostoksia kanssamme",
        "20%": "20%",
        "discount within 20 minutes": "alennus 20 minuutin kuluessa",
        "Select products": "Valitse tuotteet",
        "Any product with a 20% discount": "Mikä tahansa tuote 20% alennuksella",
        "Your discount expires in:": "Alennus päättyy",
        "hours": "tuntia",
        "minutes": "minuuttia",
        "seconds": "sekunnit",
        "Time's up!": "Aika loppui!",
    },
    'da': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Ortopædisk behandling fra top til tå",

        // other countries footer
        "United States": "USA",
        "Canada": "Canada",
        "Ireland": "Irland",
        "New Zealand": "New Zealand",
        "Germany": "Tyskland",
        "Spain": "Spanien",
        "Switzerland": "Schweiz",
        "France": "Frankrig",
        "United Kingdom": "Storbritannien",
        "Australia": "Australien",
        "Austria": "Østrig",
        "Portugal": "Portugal",
        "Sweden": "Sverige",
        "Norway": "Norge",
        "Finland": "Finland",
        "Denmark": "Danmark",
        "Italy": "Italien",
        "Netherlands": "Holland",
        "Belgium": "Belgien",
        "Czech Republic": "Tjekkiet",
        "Hungary": "Ungarn",
        "Romania": "Rumænien",
        "Greece": "Grækenland",
        "United Arab Emirates": "Forenede Arabiske Emirater",
        "Qatar": "Qatar",

        //header offer
        "Black Friday Sale": "Black Friday-tilbud",
        "Ends": "Slutter den",
        "Sale Ends": "Udsalget slutter d.",
        "20-50% OFF+Free Shipping": "50% rabat + gratis forsendelse",

        //success page
        "Thank you!": "Tak skal du have!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Vi har modtaget din ordre. Vi har netop sendt dig en bekræftelsesmail. Gå til din indbakke og se efter e-mailen. Hold øje, for bekræftelsesmailen kan ende i din spam-mappe.",
        "Order details": "Detaljer om din ordre",
        "Billing address": "Faktureringsadresse",
        "Shipping address": "Leveringsadresse",
        "Order Number": "Ordrenummer",
        "Date": "Dato",
        "Email": "E-mail",
        "Total": "Total",
        "Payment method": "Betalingsmetode",
        "Subtotal": "Subtotal",
        "Shipping": "Forsendelse",
        "Product": "Produkt",

        //applyCoupon
        "Coupon ": "Kupon ",
        " is applied successfully.": " er anvendt med succes.",
        "Coupon code": "Rabatkode",
        "Apply code": "Anvend kode",
        "Remove": "Fjern",

        //cartSidebar
        "cart": "Kurv",
        "Shop now": "Køb nu",
        "View cart": "Se kurv",
        "Includes": "Inkluderer",

        //checkout options
        "Checkout": "Kasse",
        "Or": "Eller",
        "Express checkout": "Hurtig betaling",
        "Have a promo code? Click here.": "Har du en rabatkode? Klik her.",
        "Coupon": "Kupon",
        "applied!": "Anvendt!",
        "Payment": "Betaling",
        "All transaction are secure and encrypted.": "Alle transaktioner er sikre og krypterede.",
        "Select the address that matches your card or payment method.": "Vælg den adresse, der matcher dit kort eller din betalingsmetode.",
        "Same as shipping address": "Samme som leveringsadresse",
        "Use a different billing address": "Brug en anden faktureringsadresse",
        "Show order summary": "Vis ordreoversigt",
        "Hide order summary": "Skjul ordreoversigt",

        //checkout errors
        "This value is required.": "Denne værdi er påkrævet.",
        "Must only contain digits 0 through 9.": "Må kun indeholde cifrene 0 til 9.",
        "Invalid ZIP code.": "Ugyldigt postnummer.",
        "Billing address is not filled in": "Faktureringsadresse er ikke udfyldt",
        "Invalid email.": "Ugyldig e-mail.",

        //empty cart
        "Your cart is currently empty!": "Din indkøbskurv er tom!",
        "Looks like you have no items in your shopping cart.": "Det ser ud til, at du ikke har nogen varer i din indkøbskurv.",
        "Return to home page": "Gå tilbage til startsiden",

        //checkout bottom
        "Satisfaction": "Tilfredshedsgaranti",
        "Guaranteed": " ",
        "Free": "Gratis!",
        "Exchange": "Ombytning",
        "Customer": "Kundeservice",
        "Service": " ",

        //addressInputs
        "First name": "Fornavn",
        "Last name": "Efternavn",
        "Street address": "Vejnavn",
        "Apartment, suite, etc. (optional)": "Lejlighed, suite, etc. (valgfrit)",
        "City": "By",
        "Country / Region": "Land / Region",
        "State": "Delstat",
        "Post Code / ZIP": "Postnummer",
        "Phone": "Telefon",

        //checkout buttons 
        "Continue to shipping": "Fortsæt til forsendelse",
        "Return to cart": "Tilbage til kurv",
        "Continue to payment": "Fortsæt til betaling",
        "Return to information": "Tilbage til information",
        "Complete order": "Gennemfør ordre",
        "Return to shipping": "Gå tilbage til forsendelse",

        //information form
        "Email me with news and offers": "Send mig e-mails med nyheder og tilbud",

        //payment form
        "Billing Information": "Faktureringsoplysninger",
        "Use a different billing address?": "Vil du bruge en anden faktureringsadresse?",

        //payment options
        "Credit Card (Stripe)": "Kreditkort (Stripe)",
        "Pay with your credit card via Stripe.": "Betal med dit kreditkort via Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Betal via PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Dine personlige data vil blive brugt til at behandle din ordre, understøtte din oplevelse på hele denne hjemmeside og til andre formål beskrevet i vores",
        "privacy policy": "privatlivspolitik",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Kommentarer til din ordrer, f.eks. særlige bemærkninger til levering.",
        "Shipping Information": "Forsendelsesoplysninger",

        //tnc
        "I have read and agree to the website": "Jeg har læst og accepterer webstedets",
        "terms and conditions": "vilkår og betingelser",

        //Add to cart
        "Add to cart": "Tilføj til kurv",
        "Out of stock": "Ikke på lager",

        //customerReviews
        "Rating and reviews": "Anmeldelser",
        "Based on": "Baseret på",
        "reviews": "anmeldelser",
        "Show more": "Vis mere",
        "Your email address will not be published.": "Din e-mailadresse vil ikke blive offentliggjort.",
        "Required fields are marked": "Krævede felter er markeret",
        "Your rating": "Din bedømmelse",
        "Your review": "Din anmeldelse",
        "Name": "Navn",
        "Submit": "Send ind",
        "Write review": "Skriv en anmeldelse",
        "Add a review": "Tilføj en Anmeldelse",

        //navigation
        "Home": "Forside",

        //productAccordion
        "Description": "Beskrivelse af produktet",
        "Reviews": "Anmeldelser",

        //selectProductOrder
        "Popularity": "Popularitet",
        "Average rating": "Gennemsnitlig vurdering",
        "Latest": "Seneste",
        "Price - low to high": "Pris - lav til høj",
        "Price - high to low": "Pris - høj til lav",

        //selectReviewOrder
        "Newest": "Seneste",
        "Highest rated": "Højest Rangerede",
        "Lowest rated": "Lavest Vurderet",
        "Oldest": "Ældste",
        "Sort by": "Sorter efter",

        //categoriesSection
        "Shop by Category": "Shop efter kategori",
        "Show more categories": "Vis flere kategorier",

        //reviews section
        "Google reviews": "Google-anmeldelser",
        "What Ortorex ™ Customers Say": "Hvad Ortorex™-kunder siger",

        //topSalesSection
        "Ortorex Top Sellers": "Ortorex Topsælgere",

        //customerServiceLinks
        "Customer service": "Kundeservice",

        //footer
        "Other Countries": "Andre Lande",
        "Sign Up For Exclusive Offers": "Tilmeld Dig Eksklusive Tilbud",

        //informationLinks
        "Information": "Information",

        //paymentLogos
        "Secure payments": "Sikre betalinger",

        //burgerMenuClose
        "Close main menu": "Luk hovedmenuen",
        "Open main menu": "Åbn hovedmenuen",

        //cookies
        "Decline": "Afvis",
        "Metrics:": "Målinger:",
        "Privacy Overview": "Oversigt over Privatlivets Fred",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Denne hjemmeside bruger cookies til at forbedre din oplevelse, mens du navigerer rundt på hjemmesiden. Ud af disse cookies gemmes de cookies, der er kategoriseret som nødvendige, i din browser, da de er afgørende for, at de grundlæggende funktioner på hjemmesiden fungerer. Vi bruger også tredjepartscookies, der hjælper os med at analysere og forstå, hvordan du bruger denne hjemmeside. Disse cookies vil kun blive gemt i din browser med dit samtykke. Du har også mulighed for at fravælge disse cookies. Men fravalg af nogle af disse cookies kan have en effekt på din browseroplevelse.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': ' NextRoll, Inc. ("NextRoll") og vores reklamepartnere bruger cookies og lignende teknologier på dette websted og rundt omkring på nettet til at indsamle og bruge personlige data (f.eks. din IP-adresse). Hvis du giver dit samtykke, kan cookies, enhedsidentifikatorer eller andre oplysninger gemmes eller tilgås på din enhed til de formål, der er beskrevet nedenfor. Du kan klikke på "Tillad Alle" eller "Afvis Alle" eller klikke på Indstillinger ovenfor for at tilpasse dit samtykke.',
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': " NextRoll og vores reklamepartnere behandler personlige data for at: ● gemme og/eller få adgang til oplysninger på en enhed; ● oprette en personlig indholdsprofil; ● vælge personligt indhold; ● personaliserede annoncer, annoncemåling og målgruppeindsigt; ● produktudvikling. Til nogle af formålene ovenfor bruger vores reklamepartnere: ● Bruge præcise geolokaliseringsdata. Nogle af vores partnere er afhængige af deres legitime forretningsinteresser for at behandle personlige data. Se vores reklamepartnere for at se de formål, de mener, de har en legitim interesse i, og hvordan du kan gøre indsigelse mod en sådan behandling.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": " Dine ændringer på denne side vil blive anvendt på tværs af internettet på enhver digital ejendom, du besøger, ved hjælp af NextRolls teknologi. Hvis du vælger at Afvise Alt, vil du stadig kunne se indhold på denne side, og du vil stadig modtage reklamer, men reklamerne vil ikke være skræddersyet til dig. Du kan ændre din indstilling, når du ser",
        "Show less": "Vis mindre",
        "Necessary": "Nødvendige",
        "Preferences": "Præferencer",
        "Statistics": "Statistik",
        "Marketing": "Markedsføring",
        "Always Enabled": "Altid aktiveret",
        "Enabled": "Aktiveret",
        "Disabled": "Deaktiveret",
        "Save and accept": "GEM & ACCEPTER",
        "Cookie settings": "Cookie-indstillinger",
        "Accept": "ACCEPTER",
        "Accept all": "Accepter alle",
        "We value your privacy": "Vi værdsætter dit privatliv",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": "Denne hjemmeside bruger cookies til at forbedre din oplevelse. Vi går ud fra, at du er indforstået med dette, men du kan fravælge dem, hvis du ønsker det.",
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Nødvendige cookies er helt afgørende for, at hjemmesiden kan fungere korrekt. Denne kategori omfatter kun cookies, der sikrer grundlæggende funktionaliteter og sikkerhedsfunktioner på hjemmesiden. Disse cookies gemmer ikke nogen personlige oplysninger.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Præference-cookies gør det muligt for en hjemmeside at huske oplysninger, der ændrer den måde, hjemmesiden opfører sig eller ser ud på, f.eks. dit foretrukne sprog eller den region, du befinder dig i.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Statistikcookies hjælper hjemmesideejere med at forstå, hvordan besøgende interagerer med hjemmesider ved at indsamle og rapportere oplysninger anonymt.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Marketingcookies bruges til at spore besøgende på tværs af hjemmesider. Formålet er at vise annoncer, der er relevante og engagerende for den enkelte bruger og dermed mere værdifulde for udgivere og tredjepartsannoncører.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Du har tilmeldt dig vores eksklusive tilbud!",
        "Unexpected error occurred! Please try again later.": "Der opstod en uventet fejl! Prøv venligst igen senere.",
        "Sign up for exclusive offers": "Tilmeld dig eksklusive tilbud",
        "Enter your email": "Indtast din e-mail",
        "Sign up": "Tilmeld dig",

        //loadingSpinner
        "Loading...": "Pålæsning...",

        //reviewCard
        "Read more...": "Læs mere...",

        //searchCombobox
        "No products were found matching your selection.": "Der blev ikke fundet nogen produkter, der matcher dit valg.",

        //searchInput
        "Search for...": "Søg efter...",
        "I am looking for...": "Jeg leder efter...",

        //404
        "Oops, ": "Ups, ",
        "nothing": "intet",
        " here...": " her...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "Det ser ikke ud til, at vi kan finde den side, du leder efter. Prøv at gå tilbage til forrige side.",
        "Go back": "Gå tilbage",
        "Not found": "Ikke fundet",

        //cart
        "Cart updated.": "Indkøbskurven er opdateret.",
        "Return to shop": "Gå tilbage til shop",
        "Cart": "Kurv",
        "Your cart": "Din kurv",
        "Continue shopping": "Fortsæt med at handle",
        "Price": "Pris",
        "Quantity": "Antal",
        "Coupon:": "Kupon:",

        //checkout
        "Color: ": "Farve: ",
        "Size: ": "Størrelse: ",

        //shop
        "Search": "Søg",
        "Shop": "Butik",
        "Sort by: ": "Sorter efter: ",

        //product
        "You may also like": "Du vil måske også synes om",
        "Be sure to check the size in our": "Husk at tjekke størrelsen i vores",
        "Size Guide": "Størrelsesskema",
        "This item is now sold out and unavailable": "Denne vare er nu udsolgt og ikke tilgængelig",
        "Email me when available": "Send mig en e-mail, når den er tilgængelig",
        "Your Email": "Din e-mail",
        "Send": "Send",
        "Up to 20% Off Your First Purchase": "Op Til 20% RABAT På Dit Første Køb",
        "Get Coupon": "Få rabatkode",
        "Only": "Kun",
        "left in stock": "tilbage på lager",
        "Free shipping for all orders": "Gratis forsendelse på alle ordrer",
        "bought in past month": "købt inden for den seneste måned",

        //store
        "Coupon applied successfully!": "Kupon anvendt med succes!",
        "Unknown error": "Ukendt fejl",
        "Coupon removed successfully!": "Kupon fjernet med succes!",

        //discounts
        "Thank you for shopping with us": "Tak, fordi du handler hos os",
        "20%": "20%",
        "discount within 20 minutes": "rabat inden for 20 minutter",
        "Select products": "Vælg produkter",
        "Any product with a 20% discount": "Ethvert produkt med 20% rabat",
        "Your discount expires in:": "Din rabat udløber i",
        "hours": "timer",
        "minutes": "minutter",
        "seconds": "sekunder",
        "Time's up!": "Tiden er gået!",
    },
    'nl': {
        //meta title
        "Ortorex™ - Orthopedic Treatment from Head to Toe": "Ortorex™ - Orthopedische behandeling van top tot teen",

        // other countries footer
        "United States": "Verenigde Staten",
        "Canada": "Canada",
        "Ireland": "Ierland",
        "New Zealand": "Nieuw-Zeeland",
        "Germany": "Duitsland",
        "Spain": "Spanje",
        "Switzerland": "Zwitserland",
        "France": "Frankrijk",
        "United Kingdom": "Verenigd Koninkrijk",
        "Australia": "Australië",
        "Austria": "Oostenrijk",
        "Portugal": "Portugal",
        "Sweden": "Zweden",
        "Norway": "Noorwegen",
        "Finland": "Finland",
        "Denmark": "Denemarken",
        "Italy": "Italië",
        "Netherlands": "Nederland",
        "Belgium": "België",
        "Czech Republic": "Tsjechië",
        "Hungary": "Hongarije",
        "Romania": "Roemenië",
        "Greece": "Griekenland",

        //header offer
        "Sale Ends": "Einde van de uitverkoop",
        "20-50% OFF+Free Shipping": "20-50% KORTING Gratis verzending",

        //success page
        "Thank you!": "Hartelijk dank!",
        "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "Je bestelling is ontvangen. We hebben je zojuist een bevestigingsmail gestuurd. Ga naar je inbox en zoek de e-mail. Kijk goed uit, want de bevestigingsmail kan in je spammap terechtkomen.",
        "Order details": "Bestelgegevens",
        "Billing address": "Factuuradres",
        "Shipping address": "Verzendadres",
        "Order Number": "Bestelnummer",
        "Date": "Datum",
        "Email": "E-mail",
        "Total": "Totaal",
        "Payment method": "Betaalmethode",
        "Subtotal": "Subtotaal",
        "Shipping": "Verzending",
        "Product": "Product",

        //applyCoupon
        "Coupon ": "Coupon ",
        " is applied successfully.": " is succesvol toegepast.",
        "Coupon code": "Coupon code",
        "Apply code": "Code toepassen",
        "Remove": "Verwijder",

        //cartSidebar
        "cart": "winkelwagen",
        "Shop now": "Nu winkelen",
        "View cart": "Bekijk winkelwagen",
        "Includes": "Inclusief",

        //checkout options
        "Checkout": "Afrekenen",
        "Or": "Of",
        "Express checkout": "Snel afrekenen",
        "Have a promo code? Click here.": "Heb je een promotiecode? Klik hier.",
        "Coupon": "Coupon",
        "applied!": "toegepast!",
        "Payment": "Betaling",
        "All transaction are secure and encrypted.": "Alle transacties zijn beveiligd en gecodeerd.",
        "Select the address that matches your card or payment method.": "Selecteer het adres dat overeenkomt met je kaart of betaalmethode.",
        "Same as shipping address": "Zelfde als verzendadres",
        "Use a different billing address": "Gebruik een ander factuuradres",
        "Show order summary": "Overzicht bestellingen weergeven",
        "Hide order summary": "Besteloverzicht verbergen",

        //checkout errors
        "This value is required.": "Deze waarde is verplicht.",
        "Must only contain digits 0 through 9.": "Moet alleen cijfers bevatten van 0 tot 9.",
        "Invalid ZIP code.": "Ongeldige postcode.",
        "Billing address is not filled in": "Factuuradres is niet ingevuld",
        "Invalid email.": "Ongeldige e-mail.",

        //empty cart
        "Your cart is currently empty!": "Uw winkelwagentje is momenteel leeg!",
        "Looks like you have no items in your shopping cart.": "Het lijkt erop dat u geen artikelen in uw winkelwagentje heeft.",
        "Return to home page": "Terug naar de startpagina",

        //checkout bottom
        "Satisfaction": "Tevredenheid",
        "Guaranteed": "Gegarandeerd",
        "Free": "Gratis",
        "Exchange": "Ruilen",
        "Customer": "Klant",
        "Service": "Service",

        //addressInputs
        "First name": "Voornaam",
        "Last name": "Achternaam",
        "Street address": "Adres",
        "Apartment, suite, etc. (optional)": "Appartement, suite, etc. (optioneel)",
        "City": "Stad",
        "Country / Region": "Land / Regio",
        "State": "Staat",
        "Post Code / ZIP": "Postcode",
        "Phone": "Telefoon",

        //checkout buttons 
        "Continue to shipping": "Doorgaan naar verzending",
        "Return to cart": "Terug naar winkelwagen",
        "Continue to payment": "Doorgaan naar betaling",
        "Return to information": "Terug naar informatie",
        "Complete order": "Bestelling afronden",
        "Return to shipping": "Terug naar verzending",

        //information form
        "Email me with news and offers": "E-mail me met nieuws en aanbiedingen",

        //payment form
        "Billing Information": "Factureringsgegevens",
        "Use a different billing address?": "Ander factuuradres gebruiken?",

        //payment options
        "Credit Card (Stripe)": "Kredietkaart (Stripe)",
        "Pay with your credit card via Stripe.": "Betaal met je creditcard via Stripe.",
        "PayPal": "PayPal",
        "Pay via PayPal.": "Betaal via PayPal.",

        //personal data message
        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "Uw persoonlijke gegevens worden gebruikt om uw bestelling te verwerken, uw ervaring op deze website te ondersteunen en voor andere doeleinden zoals beschreven in ons ",
        "privacy policy": "privacybeleid",

        //shippingForm
        "Notes about your order, e.g. special notes for delivery.": "Opmerkingen over uw bestelling, bijvoorbeeld speciale opmerkingen voor de levering.",
        "Shipping Information": "Verzendinformatie",

        //tnc
        "I have read and agree to the website": "Ik heb de website gelezen en ga akkoord met de",
        "terms and conditions": "algemene voorwaarden",

        //Add to cart
        "Add to cart": "Toevoegen aan winkelwagentje",

        //customerReviews
        "Rating and reviews": "Beoordeling en beoordelingen",
        "Based on": "Gebaseerd op",
        "reviews": "beoordelingen",
        "Show more": "Meer weergeven",
        "Your email address will not be published.": "Je e-mailadres wordt niet gepubliceerd.",
        "Required fields are marked": "Verplichte velden zijn gemarkeerd",
        "Your rating": "Uw beoordeling",
        "Your review": "Jouw beoordeling",
        "Name": "Naam",
        "Submit": "Stuur",
        "Write review": "Schrijf recensie",
        "Add a review": "Een recensie toevoegen",

        //navigation
        "Home": "Home",

        //productAccordion
        "Description": "Beschrijving",
        "Reviews": "Beoordelingen",

        //selectProductOrder
        "Popularity": "Populariteit",
        "Average rating": "Gemiddelde waardering",
        "Latest": "Nieuwste",
        "Price - low to high": "Prijs - laag naar hoog",
        "Price - high to low": "Prijs - hoog naar laag",

        //selectReviewOrder
        "Newest": "Nieuwste",
        "Highest rated": "Hoogst gewaardeerd",
        "Lowest rated": "Laagst gewaardeerd",
        "Oldest": "Oudste",
        "Sort by": "Sorteer door",

        //categoriesSection
        "Shop by Category": "Winkelen per categorie",
        "Show more categories": "Meer categorieën weergeven",

        //reviews section
        "Google reviews": "Google beoordelingen",
        "What Ortorex ™ Customers Say": "Wat Ortorex™ klanten zeggen",

        //topSalesSection
        "Ortorex Top Sellers": "Ortorex best verkocht",

        //customerServiceLinks
        "Customer service": "Klantenservice",

        //footer
        "Other Countries": "Andere landen",
        "Sign Up For Exclusive Offers": "Aanmelden Voor Exclusieve Aanbiedingen",

        //informationLinks
        "Information": "Informatie",

        //paymentLogos
        "Secure payments": "Beveiligde betalingen",

        //burgerMenuClose
        "Close main menu": "Hoofdmenu sluiten",
        "Open main menu": "Hoofdmenu openen",

        //cookies
        "Decline": "Weigeren",
        "Metrics:": "Metriek:",
        "Privacy Overview": "Privacyoverzicht",
        "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.": "Deze website maakt gebruik van cookies om uw ervaring tijdens het navigeren door de website te verbeteren. Van deze cookies worden de cookies die zijn gecategoriseerd als noodzakelijk in uw browser opgeslagen, omdat ze essentieel zijn voor het functioneren van de basisfuncties van de website. We gebruiken ook cookies van derden die ons helpen te analyseren en te begrijpen hoe u deze website gebruikt. Deze cookies worden alleen met uw toestemming in uw browser opgeslagen. U hebt ook de mogelijkheid om deze cookies uit te schakelen. Maar het uitschakelen van sommige van deze cookies kan gevolgen hebben voor uw surfervaring.",
        'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.': 'Deze website maakt gebruik van cookies om uw ervaring tijdens het navigeren door de website te verbeteren. Van deze cookies worden de cookies die zijn gecategoriseerd als noodzakelijk in uw browser opgeslagen, omdat ze essentieel zijn voor het functioneren van de basisfuncties van de website. We gebruiken ook cookies van derden die ons helpen te analyseren en te begrijpen hoe u deze website gebruikt. Deze cookies worden alleen met uw toestemming in uw browser opgeslagen. U hebt ook de mogelijkheid om deze cookies uit te schakelen. Maar het uitschakelen van sommige van deze cookies kan gevolgen hebben voor uw surfervaring.',
        'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.': " NextRoll en onze reclamepartners verwerken persoonlijke gegevens om: ● Informatie op een apparaat op te slaan en/of te openen; ● Een gepersonaliseerd inhoudsprofiel aan te maken; ● Gepersonaliseerde inhoud te selecteren; ● Gepersonaliseerde advertenties, advertentiemeting en publieksinzichten; ● Productontwikkeling. Voor sommige van de bovenstaande doeleinden kunnen onze reclamepartners: ● Precieze geolocatiegegevens gebruiken. Sommige van onze partners doen een beroep op hun legitieme zakelijke belangen om persoonsgegevens te verwerken. Bekijk onze reclamepartners om te zien voor welke doeleinden zij menen een legitiem belang te hebben en hoe u bezwaar kunt maken tegen een dergelijke verwerking.",
        "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see": " Uw wijzigingen op deze site worden toegepast op alle digitale eigendommen die u bezoekt met behulp van de technologie van NextRoll. Als u Alles weigeren selecteert, kunt u nog steeds inhoud op deze site bekijken en ontvangt u nog steeds reclame, maar de reclame wordt niet op u afgestemd. U kunt uw instelling wijzigen wanneer u",
        "Show less": "Minder tonen",
        "Necessary": "Noodzakelijk",
        "Preferences": "Voorkeuren",
        "Statistics": "Statistieken",
        "Marketing": "Marketing",
        "Always Enabled": "Altijd ingeschakeld",
        "Enabled": "Ingeschakeld",
        "Disabled": "Uitgeschakeld",
        "Save and accept": "OPSLAAN & ACCEPTEREN",
        "Cookie settings": "Cookie instellingen",
        "Accept": "ACCEPTER",
        "Accept all": "Alles accepteren",
        "We value your privacy": "Wij hechten waarde aan uw privacy",
        "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.": 'Wij gebruiken cookies op onze website om u de meest relevante ervaring te geven door uw voorkeuren en herhaalde bezoeken te onthouden. Door op "Accepteren" te klikken, geef je toestemming voor het gebruik van ALLE cookies.',
        "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.": "Noodzakelijke cookies zijn absoluut noodzakelijk voor het goed functioneren van de website. Deze categorie omvat alleen cookies die basisfunctionaliteiten en beveiligingsfuncties van de website garanderen. Deze cookies slaan geen persoonlijke informatie op.",
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.": "Met voorkeurscookies kan een website informatie onthouden die de manier verandert waarop de website zich gedraagt of eruitziet, zoals de taal van uw voorkeur of de regio waarin u zich bevindt.",
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.": "Statistische cookies helpen website-eigenaren te begrijpen hoe bezoekers omgaan met websites door anoniem informatie te verzamelen en te rapporteren.",
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.": "Statistische cookies helpen website-eigenaren te begrijpen hoe bezoekers omgaan met websites door anoniem informatie te verzamelen en te rapporteren.",

        //emailSignUp
        "You've successfully signed up for our exclusive offers!": "Je hebt je succesvol aangemeld voor onze exclusieve aanbiedingen!",
        "Unexpected error occurred! Please try again later.": "Onverwachte fout opgetreden! Probeer het later nog eens.",
        "Sign up for exclusive offers": "Aanmelden voor exclusieve aanbiedingen",
        "Enter your email": "Voer uw e-mailadres in",
        "Sign up": "Aanmelden",

        //loadingSpinner
        "Loading...": "Bezig met laden...",

        //reviewCard
        "Read more...": "Meer lezen...",

        //searchCombobox
        "No products were found matching your selection.": "Er zijn geen producten gevonden die aan uw selectie voldoen.",

        //searchInput
        "Search for...": "Zoeken naar...",
        "I am looking for...": "Ik ben op zoek naar...",

        //404
        "Oops, ": "Oeps, ",
        "nothing": "niets",
        " here...": " hier...",
        "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "We kunnen de pagina die u zoekt niet vinden. Probeer terug te gaan naar de vorige pagina.",
        "Go back": "Ga terug",
        "Not found": "Niet gevonden",

        //cart
        "Cart updated.": "Winkelwagen bijgewerkt.",
        "Return to shop": "Terug naar winkel",
        "Cart": "Winkelwagen",
        "Your cart": "Uw winkelwagen",
        "Continue shopping": "Verder winkelen",
        "Price": "Prijs",
        "Quantity": "Hoeveelheid",
        "Coupon:": "Coupon:",

        //checkout
        "Color: ": "Kleur: ",
        "Size: ": "Maat: ",

        //shop
        "Search": "Zoeken",
        "Shop": "Winkelen",
        "Sort by: ": "Sorteren op:",

        //product
        "You may also like": "Vind je ook leuk",
        "Be sure to check the size in our": "Controleer de maat in onze",
        "Size Guide": "Maattabel",
        "This item is now sold out and unavailable": "Dit item is nu uitverkocht en niet beschikbaar",
        "Email me when available": "Stuur me een e-mail wanneer het weer beschikbaar is",
        "Your Email": "Jouw e-mail",
        "Send": "Stuur",
        "Up to 20% Off Your First Purchase": "Tot 20% Korting Op Je Eerste Aankoop",
        "Get Coupon": "Ontvang Coupon:",
        "Only": "Alleen",
        "left in stock": "nog op voorraad",
        "Free shipping for all orders": "Gratis verzending voor alle bestellingen",
        "bought in past month": "verkocht in de afgelopen maandag",

        //store
        "Coupon applied successfully!": "Coupon succesvol toegepast!",
        "Unknown error": "Onbekende fout",
        "Coupon removed successfully!": "Coupon succesvol verwijderd!",

        //discounts
        "Thank you for shopping with us": "Bedankt voor het winkelen bij ons",
        "20%": "20%",
        "discount within 20 minutes": "korting binnen 20 minuten",
        "Select products": "Selecteer producten",
        "Any product with a 20% discount": "Elk product met 20% korting",
        "Your discount expires in:": "Uw korting vervalt in",
        "hours": "uur",
        "minutes": "minuten",
        "seconds": "seconden",
        "Time's up!": "De tijd is om!",
    },
    // 'cs': {
    //     //meta title
    //     "Ortorex™ - Orthopedic Treatment from Head to Toe":"",

    //     // other countries footer
    //     "United States":"",
    //     "Canada":"",
    //     "Ireland":"",
    //     "New Zealand":"",
    //     "Germany":"",
    //     "Spain":"",
    //     "Switzerland":"",
    //     "France":"",
    //     "United Kingdom":"",
    //     "Australia":"",
    //     "Austria":"",
    //     "Portugal":"",
    //     "Sweden":"",
    //     "Norway":"",
    //     "Finland":"",
    //     "Denmark":"",
    //     "Italy":"",
    //     "Netherlands":"",
    //     "Belgium":"",
    //     "Czech Republic":"",
    //     "Hungary":"",
    //     "Romania":"",
    //     "Greece":"",

    //     //header offer
    //     "Sale Ends":"",
    //     "20-50% OFF+Free Shipping":"",

    //     //success page
    //     "Thank you!": "",
    //     "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "",
    //     "Order details": "",
    //     "Billing address": "",
    //     "Shipping address": "",
    //     "Order Number": "",
    //     "Date": "",
    //     "Email": "",
    //     "Total": "",
    //     "Payment method": "",
    //     "Subtotal": "",
    //     "Shipping": "",
    //     "Product": "",

    //     //applyCoupon
    //     "Coupon ": "",
    //     " is applied successfully.": "",
    //     "Coupon code": "",
    //     "Apply code": "",
    //     "Remove": "",

    //     //cartSidebar
    //     "cart": "",
    //     "Shop now": "",
    //     "View cart": "",
    //     "Includes": "",

    //     //checkout options
    //     "Checkout": "",
    //     "Or": "",
    //     "Express checkout": "",
    //     "Have a promo code? Click here.": "",
    //     "Coupon": "",
    //     "applied!": "",
    //     "Payment": "",
    //     "All transaction are secure and encrypted.": "",
    //     "Select the address that matches your card or payment method.": "",
    //     "Same as shipping address":"",
    //     "Use a different billing address":"",
    //     "Show order summary":"",
    //     "Hide order summary":"",

    //     //checkout errors
    //     "This value is required.": "",
    //     "Must only contain digits 0 through 9.": "",
    //     "Invalid ZIP code.": "",
    //     "Billing address is not filled in":"",
    //     "Invalid email.": "",

    //     //empty cart
    //     "Your cart is currently empty!": "",
    //     "Looks like you have no items in your shopping cart.": "",
    //     "Return to home page": "",

    //     //checkout bottom
    //     "Satisfaction": "",
    //     "Guaranteed": "",
    //     "Free": "",
    //     "Exchange": "",
    //     "Customer": "",
    //     "Service": "",

    //     //addressInputs
    //     "First name": "",
    //     "Last name": "",
    //     "Street address": "",
    //     "Apartment, suite, etc. (optional)": "",
    //     "City": "",
    //     "Country / Region": "",
    //     "State": "",
    //     "Post Code / ZIP": "",
    //     "Phone": "",

    //     //checkout buttons 
    //     "Continue to shipping": "",
    //     "Return to cart": "",
    //     "Continue to payment": "",
    //     "Return to information": "",
    //     "Complete order": "",
    //     "Return to shipping": "",

    //     //information form
    //     "Email me with news and offers": "",

    //     //payment form
    //     "Billing Information": "",
    //     "Use a different billing address?": "",

    //     //payment options
    //     "Credit Card (Stripe)": "",
    //     "Pay with your credit card via Stripe.": "",
    //     "PayPal": "",
    //     "Pay via PayPal.": "",

    //     //personal data message
    //     "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "",
    //     "privacy policy": "",

    //     //shippingForm
    //     "Notes about your order, e.g. special notes for delivery.": "",
    //     "Shipping Information": "",

    //     //tnc
    //     "I have read and agree to the website": "",
    //     "terms and conditions": "",

    //     //Add to cart
    //     "Add to cart": "",

    //     //customerReviews
    //     "Rating and reviews": "",
    //     "Based on": "",
    //     "reviews": "",
    //     "Show more": "",
    //     "Your email address will not be published.": "",
    //     "Required fields are marked": "",
    //     "Your rating": "",
    //     "Your review": "",
    //     "Name": "",
    //     "Submit": "",
    //     "Write review": "",
    // "Add a review": "",

    //     //navigation
    //     "Home": "",

    //     //productAccordion
    //     "Description": "",
    //     "Reviews": "",

    //     //selectProductOrder
    //     "Popularity": "",
    //     "Average rating": "",
    //     "Latest": "",
    //     "Price - low to high": "",
    //     "Price - high to low": "",

    //     //selectReviewOrder
    //     "Newest": "",
    //     "Highest rated": "",
    //     "Lowest rated": "",
    //     "Oldest": "",
    //     "Sort by": "",

    //     //categoriesSection
    //     "Shop by Category": "",
    //     "Show more categories": "",

    //     //reviews section
    //     "Google reviews": "",
    //     "What Ortorex ™ Customers Say": "",

    //     //topSalesSection
    //     "Ortorex Top Sellers": "",

    //     //customerServiceLinks
    //     "Customer service": "",

    //     //footer
    //     "Other Countries": "",
    //     "Sign Up For Exclusive Offers": "",

    //     //informationLinks
    //     "Information": "",

    //     //paymentLogos
    //     "Secure payments": "",

    //     //burgerMenuClose
    //     "Close main menu": "",
    //     "Open main menu": "",

    // //cookies
    // "Decline": "",
    // "Metrics:": "",
    // "Privacy Overview":"",
    // "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.":"",
    // 'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.':"",
    // 'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.':"",
    // "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see":"",
    // "Show less":"",
    // "Necessary":"",
    // "Preferences":"",
    // "Statistics":"",
    // "Marketing":"",
    // "Always Enabled":"",
    // "Enabled":"",
    // "Disabled":"",
    // "Save and accept":"",
    // "Cookie settings":"",
    // "Accept":"",
    // "Accept all": "",
    // "We value your privacy": "",
    // "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.":"",
    // "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.":"",
    // "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.":"",
    // "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.":"",
    // "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.":"",

    //     //emailSignUp
    //     "You've successfully signed up for our exclusive offers!": "",
    //     "Unexpected error occurred! Please try again later.": "",
    //     "Sign up for exclusive offers": "",
    //     "Enter your email":"",
    //     "Sign up":"",

    //     //loadingSpinner
    //     "Loading...": "",

    //     //reviewCard
    //     "Read more...": "",

    //     //searchCombobox
    //     "No products were found matching your selection.": "",

    //     //searchInput
    //     "Search for...": "",
    // "I am looking for...": "",

    //     //404
    //     "Oops, ": "",
    //     "nothing": "",
    //     " here...": "",
    //     "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "",
    //     "Go back": "",
    //     "Not found": "",

    //     //cart
    //     "Cart updated.": "",
    //     "Return to shop": "",
    //     "Cart": "",
    //     "Your cart": "",
    //     "Continue shopping": "",
    //     "Price": "",
    //     "Quantity": "",
    //     "Coupon:": "",

    //     //checkout
    //     "Color: ": "",
    //     "Size: ": "",

    //     //shop
    //     "Search": "",
    //     "Shop": "",
    //     "Sort by: ": "",

    //     //product
    //     "You may also like": "",
    //     "Be sure to check the size in our": "",
    //     "Size Guide": "",
    //     "This item is now sold out and unavailable": "",
    //     "Email me when available": "",
    //     "Your Email": "",
    //     "Send": "",
    //     "Up to 20% Off Your First Purchase": "",
    //     "Get Coupon": "",
    //     "Only":"",
    //     "left in stock":"",
    //     "Free shipping for all orders":"",
    //     "bought in past month": "",

    //     //store
    //     "Coupon applied successfully!": "",
    //     "Unknown error": "",
    //     "Coupon removed successfully!": "",

    //     //discounts
    //     "Thank you for shopping with us": "",
    //     "20%": "20%",
    //     "discount within 20 minutes": "",
    //     "Select products": "",
    //     "Any product with a 20% discount": "",
    //     "Your discount expires in:": "",
    //     "hours": "",
    //     "minutes": "",
    //     "seconds": "",
    //     "Time's up!": "",
    // },
    // 'temp': {
    //     //meta title
    //     "Ortorex™ - Orthopedic Treatment from Head to Toe":"",

    //     // other countries footer
    //     "United States":"",
    //     "Canada":"",
    //     "Ireland":"",
    //     "New Zealand":"",
    //     "Germany":"",
    //     "Spain":"",
    //     "Switzerland":"",
    //     "France":"",
    //     "United Kingdom":"",
    //     "Australia":"",
    //     "Austria":"",
    //     "Portugal":"",
    //     "Sweden":"",
    //     "Norway":"",
    //     "Finland":"",
    //     "Denmark":"",
    //     "Italy":"",
    //     "Netherlands":"",
    //     "Belgium":"",
    //     "Czech Republic":"",
    //     "Hungary":"",
    //     "Romania":"",
    //     "Greece":"",

    //     //header offer
    //     "Sale Ends":"",
    //     "20-50% OFF+Free Shipping":"",

    //     //success page
    //     "Thank you!": "",
    //     "Your order has been received. We have just sent you a confirmation email. Just go to your email inbox and look for the email. Keep a look out because the confirmatione mail might end up in your spam folder.": "",
    //     "Order details": "",
    //     "Billing address": "",
    //     "Shipping address": "",
    //     "Order Number": "",
    //     "Date": "",
    //     "Email": "",
    //     "Total": "",
    //     "Payment method": "",
    //     "Subtotal": "",
    //     "Shipping": "",
    //     "Product": "",

    //     //applyCoupon
    //     "Coupon ": "",
    //     " is applied successfully.": "",
    //     "Coupon code": "",
    //     "Apply code": "",
    //     "Remove": "",

    //     //cartSidebar
    //     "cart": "",
    //     "Shop now": "",
    //     "View cart": "",
    //     "Includes": "",

    //     //checkout options
    //     "Checkout": "",
    //     "Or": "",
    //     "Express checkout": "",
    //     "Have a promo code? Click here.": "",
    //     "Coupon": "",
    //     "applied!": "",
    //     "Payment": "",
    //     "All transaction are secure and encrypted.": "",
    //     "Select the address that matches your card or payment method.": "",
    //     "Same as shipping address":"",
    //     "Use a different billing address":"",
    //     "Show order summary":"",
    //     "Hide order summary":"",

    //     //checkout errors
    //     "This value is required.": "",
    //     "Must only contain digits 0 through 9.": "",
    //     "Invalid ZIP code.": "",
    //     "Billing address is not filled in":"",
    //     "Invalid email.": "",

    //     //empty cart
    //     "Your cart is currently empty!": "",
    //     "Looks like you have no items in your shopping cart.": "",
    //     "Return to home page": "",

    //     //checkout bottom
    //     "Satisfaction": "",
    //     "Guaranteed": "",
    //     "Free": "",
    //     "Exchange": "",
    //     "Customer": "",
    //     "Service": "",

    //     //addressInputs
    //     "First name": "",
    //     "Last name": "",
    //     "Street address": "",
    //     "Apartment, suite, etc. (optional)": "",
    //     "City": "",
    //     "Country / Region": "",
    //     "State": "",
    //     "Post Code / ZIP": "",
    //     "Phone": "",

    //     //checkout buttons 
    //     "Continue to shipping": "",
    //     "Return to cart": "",
    //     "Continue to payment": "",
    //     "Return to information": "",
    //     "Complete order": "",
    //     "Return to shipping": "",

    //     //information form
    //     "Email me with news and offers": "",

    //     //payment form
    //     "Billing Information": "",
    //     "Use a different billing address?": "",

    //     //payment options
    //     "Credit Card (Stripe)": "",
    //     "Pay with your credit card via Stripe.": "",
    //     "PayPal": "",
    //     "Pay via PayPal.": "",

    //     //personal data message
    //     "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our ": "",
    //     "privacy policy": "",

    //     //shippingForm
    //     "Notes about your order, e.g. special notes for delivery.": "",
    //     "Shipping Information": "",

    //     //tnc
    //     "I have read and agree to the website": "",
    //     "terms and conditions": "",

    //     //Add to cart
    //     "Add to cart": "",

    //     //customerReviews
    //     "Rating and reviews": "",
    //     "Based on": "",
    //     "reviews": "",
    //     "Show more": "",
    //     "Your email address will not be published.": "",
    //     "Required fields are marked": "",
    //     "Your rating": "",
    //     "Your review": "",
    //     "Name": "",
    //     "Submit": "",
    //     "Write review": "",
    // "Add a review": "",

    //     //navigation
    //     "Home": "",

    //     //productAccordion
    //     "Description": "",
    //     "Reviews": "",

    //     //selectProductOrder
    //     "Popularity": "",
    //     "Average rating": "",
    //     "Latest": "",
    //     "Price - low to high": "",
    //     "Price - high to low": "",

    //     //selectReviewOrder
    //     "Newest": "",
    //     "Highest rated": "",
    //     "Lowest rated": "",
    //     "Oldest": "",
    //     "Sort by": "",

    //     //categoriesSection
    //     "Shop by Category": "",
    //     "Show more categories": "",

    //     //reviews section
    //     "Google reviews": "",
    //     "What Ortorex ™ Customers Say": "",

    //     //topSalesSection
    //     "Ortorex Top Sellers": "",

    //     //customerServiceLinks
    //     "Customer service": "",

    //     //footer
    //     "Other Countries": "",
    //     "Sign Up For Exclusive Offers": "",

    //     //informationLinks
    //     "Information": "",

    //     //paymentLogos
    //     "Secure payments": "",

    //     //burgerMenuClose
    //     "Close main menu": "",
    //     "Open main menu": "",

    // //cookies
    // "Decline": "",
    // "Metrics:": "",
    // "Privacy Overview":"",
    // "This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.":"",
    // 'NextRoll, Inc. ("NextRoll") and our advertising partners use cookies and similar technologies on this site and around the web to collect and use personal data (e.g., your IP address). If you consent, the cookies, device identifiers, or other information can be stored or accessed on your device for the purposes described below. You can click "Allow All" or "Decline All" or click Settings above to customize your consent.':"",
    // 'NextRoll and our advertising partners process personal data to: ● Store and/or access information on a device; ● Create a personalized content profile; ● Select personalised content; ● Personalized ads, ad measurement and audience insights; ● Product development. For some of the purposes above, our advertising partners: ● Use precise geolocation data. Some of our partners rely on their legitimate business interests to process personal data. View our advertising partners to see the purposes they believe they have a legitimate interest for and how you can object to such processing.':"",
    // "Your changes on this site will be applied across the internet on any digital property you visit using NextRoll's technology. If you select Decline All, you will still be able to view content on this site and you will still receive advertising, but the advertising will not be tailored for you. You may change your setting whenever you see":"",
    // "Show less":"",
    // "Necessary":"",
    // "Preferences":"",
    // "Statistics":"",
    // "Marketing":"",
    // "Always Enabled":"",
    // "Enabled":"",
    // "Disabled":"",
    // "Save and accept":"",
    // "Cookie settings":"",
    // "Accept":"",
    // "Accept all": "",
    // "We value your privacy": "",
    // "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.":"",
    // "Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.":"",
    // "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.":"",
    // "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.":"",
    // "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.":"",


    //     //emailSignUp
    //     "You've successfully signed up for our exclusive offers!": "",
    //     "Unexpected error occurred! Please try again later.": "",
    //     "Sign up for exclusive offers": "",
    //     "Enter your email":"",
    //     "Sign up":"",

    //     //loadingSpinner
    //     "Loading...": "",

    //     //reviewCard
    //     "Read more...": "",

    //     //searchCombobox
    //     "No products were found matching your selection.": "",

    //     //searchInput
    //     "Search for...": "",
    // "I am looking for...": "",

    //     //404
    //     "Oops, ": "",
    //     "nothing": "",
    //     " here...": "",
    //     "We can’t seem to find the page you’re looking for. Try doing back to previous page.": "",
    //     "Go back": "",
    //     "Not found": "",

    //     //cart
    //     "Cart updated.": "",
    //     "Return to shop": "",
    //     "Cart": "",
    //     "Your cart": "",
    //     "Continue shopping": "",
    //     "Price": "",
    //     "Quantity": "",
    //     "Coupon:": "",

    //     //checkout
    //     "Color: ": "",
    //     "Size: ": "",

    //     //shop
    //     "Search": "",
    //     "Shop": "",
    //     "Sort by: ": "",

    //     //product
    //     "You may also like": "",
    //     "Be sure to check the size in our": "",
    //     "Size Guide": "",
    //     "This item is now sold out and unavailable": "",
    //     "Email me when available": "",
    //     "Your Email": "",
    //     "Send": "",
    //     "Up to 20% Off Your First Purchase": "",
    //     "Get Coupon": "",
    //     "Only":"",
    //     "left in stock":"",
    //     "Free shipping for all orders":"",
    //     "bought in past month": "",

    //     //store
    //     "Coupon applied successfully!": "",
    //     "Unknown error": "",
    //     "Coupon removed successfully!": "",

    //     //discounts
    //     "Thank you for shopping with us": "",
    //     "20%": "20%",
    //     "discount within 20 minutes": "",
    //     "Select products": "",
    //     "Any product with a 20% discount": "",
    //     "Your discount expires in:": "",
    //     "hours": "",
    //     "minutes": "",
    //     "seconds": "",
    //     "Time's up!": "",
    // },
}
